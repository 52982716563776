import { FC, useState, useEffect, useRef } from "react";
import { Button, Input, message, Modal, Typography, InputRef } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { OrganizationResource } from "@clerk/types/dist/organization";
import { Project } from "../../types/project";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_TASK } from "../../apolloClient/queryConstraint";

type Props = {
  isOpen: boolean;
  handleCancel: () => void;
  organization?: OrganizationResource | null;
  project: Project;
};
const NextTaskModal: FC<Props> = ({
  handleCancel,
  isOpen,
  project,
  organization,
}) => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const [searchValue, setSearchValue] = useState<string>();
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (isOpen) {
      setSearchValue("");
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const [getTask, { loading, error }] = useLazyQuery(SEARCH_TASK, {
    fetchPolicy: "no-cache",
  });

  const handleSearch = () => {
    if (!searchValue) {
      message.error({
        content: "タスク名を入力してください。",
      });
      return;
    }
    getTask({
      variables: {
        slug: project.slug,
        taskName: searchValue,
      },
    })
      .then(({ data }) => {
        if (data) {
          nav(
            `/organization/${organization?.id}/projects/${project?.slug}/tasks/${data.searchOneTask?.id}`
          );
          handleCancel();
        }
        if (!data) {
          message.error({
            content: "タスクが見つかりませんでした。",
          });
        }
      })
      .catch(async () => {
        if (error) {
          message.error({
            content: <>{t(error.graphQLErrors[0].extensions.code as string)}</>,
          });
        }
      });
  };

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      title={
        <Typography.Title level={4}>
          {t("do_you_want_to_continue_task")}
        </Typography.Title>
      }
      okText={
        <Link to={`/organization/${organization?.id}/projects/${project.slug}`}>
          {t("back_to_project_screen")}
        </Link>
      }
      cancelText={t("cancel")}
    >
      <Input
        style={{
          width: "80%",
          marginRight: "2%",
        }}
        value={searchValue}
        ref={inputRef}
        placeholder={t("enter_task_name")}
        onChange={({ target: { value } }) => setSearchValue(value)}
        autoFocus
      />
      <Button disabled={loading} loading={loading} onClick={handleSearch}>
        {t("search")}
      </Button>
    </Modal>
  );
};
export default NextTaskModal;
