import { FC } from "react";
import { Typography } from "antd";
import { OrganizationResource } from "@clerk/types/dist/organization";
import { UserResource } from "@clerk/types/dist/user";
import { Trans, useTranslation } from "react-i18next";
type Props = {
  user?: UserResource | null;
  organization?: OrganizationResource | null;
};

const { Title } = Typography;
const AdminHomescreen: FC<Props> = ({ organization, user }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>
        <Trans
          i18nKey={"welcome_text"}
          values={{
            user_name: user?.username,
            organization_name: organization?.name,
          }}
        />
      </Title>

      <Title level={3}>
        {t("organization")}: {organization?.name}
      </Title>
    </>
  );
};
export default AdminHomescreen;
