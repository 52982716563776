import { FC, useState } from "react";
import { Button, message, Typography } from "antd";
import CreateProjectModal from "../CreateProjectModal/CreateProjectModal";
import OrganizationAuthorization from "../OrganizationAuthorization/OrganizationAuthorization";
import { useOrganization, useUser } from "@clerk/clerk-react";
import { useMutation } from "@apollo/client";
import { GET_PROJECTS } from "../../apolloClient/queryConstraint";
import { CREATE_PROJECT } from "../../apolloClient/mutation";
import { useTranslation } from "react-i18next";
import ProjectList from "../ProjectList/ProjectList";
import { getUserRole } from "../../utils/utilize";
import { useFileReader } from "../../hooks/useFileReader";
import useProjectsQuery from "../../hooks/useProjectsQuery";

type Props = {};

const { Title } = Typography;
const ProjectScreen: FC<Props> = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { organization } = useOrganization();
  const role = getUserRole({ user, organization });
  const [messageApi, contextHolder] = message.useMessage();
  const [file, setFile] = useState<File>();
  const { result } = useFileReader(file);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { projects, loading } = useProjectsQuery(organization?.slug);

  const [createProject, { error }] = useMutation(CREATE_PROJECT);

  const handleChangeFile = (file: File) => {
    setFile(file);
  };

  const handleSubmit = (value: any) => {
    setIsSubmitting(true);
    createProject({
      variables: {
        orgSlug: organization?.slug,
        projectSlug: value.project_slug,
        projectName: value.project_name,
        blueprint: !!value.project_blueprint ? result : null,
      },
      refetchQueries: [GET_PROJECTS],
    })
      .then(async () => {
        await messageApi.success({
          content: `Successfully create ${value.project_name}`,
        });
        setIsSubmitting(false);
        setIsModalOpen(false);
      })
      .catch(async () => {
        if (error)
          await messageApi.error({
            content: <>{t(error.graphQLErrors[0].extensions.code as string)}</>,
          });
        setIsSubmitting(false);
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <OrganizationAuthorization organization={organization}>
      {contextHolder}
      <Title level={2}>{t("projects")}</Title>
      <Button onClick={() => setIsModalOpen(true)}>
        {t("create_project")}
      </Button>
      <ProjectList
        organization={organization}
        projects={projects}
        loading={loading}
        role={role}
      />
      <CreateProjectModal
        handleChangeFile={handleChangeFile}
        isSubmitting={isSubmitting}
        isModalOpen={isModalOpen}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
      />
    </OrganizationAuthorization>
  );
};
export default ProjectScreen;
