import { FC, useState } from "react";
import {
  Button,
  Dropdown,
  MenuProps,
  message,
  Modal,
  Space,
  Typography,
} from "antd";

import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  SettingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { Trans, useTranslation } from "react-i18next";
import { Project } from "../../types/project";
import { Link } from "react-router-dom";
import { OrganizationResource } from "@clerk/types/dist/organization";
import { useMutation } from "@apollo/client";
import { ARCHIVE_PROJECT, CREATE_PROJECT } from "../../apolloClient/mutation";
import { GET_PROJECTS } from "../../apolloClient/queryConstraint";
import DuplicateProjectModal from "../DuplicateProjectModal/DuplicateProjectModal";

type Props = {
  organization?: OrganizationResource | null;
  currentProject: Project;
};

const { Text } = Typography;
const ProjectMenu: FC<Props> = ({ currentProject, organization }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, messageHolder] = message.useMessage();
  const [archiveProject, { error }] = useMutation(ARCHIVE_PROJECT, {
    refetchQueries: [GET_PROJECTS],
  });
  const [createProject, { error: createProjectErr }] = useMutation(
    CREATE_PROJECT,
    {
      refetchQueries: [GET_PROJECTS],
    }
  );

  const handleSubmit = (values: any) => {
    setIsSubmitting(true);
    createProject({
      variables: {
        orgSlug: organization?.slug,
        projectSlug: values.project_slug,
        projectName: values.project_name,
        blueprint: currentProject.blueprint,
        setting: currentProject.setting,
      },
    })
      .then(async () => {
        messageApi.success({
          content: "Success",
        });
        setIsSubmitting(false);
        handleCancel();
      })
      .catch(async () => {
        if (createProjectErr)
          await messageApi.error({
            content: (
              <>
                {t(createProjectErr.graphQLErrors[0].extensions.code as string)}
              </>
            ),
          });
        setIsSubmitting(false);
      });
  };

  const handleOpen = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  const handleConfirmDelete = () => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: (
        <Trans
          i18nKey={"confirm_delete_project"}
          values={{
            project_name: currentProject.name,
          }}
        />
      ),
      onOk: () => {
        archiveProject({
          variables: {
            slug: currentProject.slug,
          },
        })
          .then(async () => {
            await messageApi.success({ content: "Success" });
          })
          .catch(async () => {
            if (error)
              await messageApi.error({
                content: (
                  <>{t(error.graphQLErrors[0].extensions.code as string)}</>
                ),
              });
          });
      },
    });
  };
  const items: MenuProps["items"] = [
    {
      label: <Text onClick={handleOpen}>{t("duplicate")}</Text>,
      key: "0",
      icon: <CopyOutlined />,
    },
    {
      label: (
        <Link
          to={`/organization/${organization?.id}/projects/${currentProject.slug}`}
        >
          <Text>{t("settings")}</Text>
        </Link>
      ),
      key: "1",
      icon: <SettingOutlined />,
    },
    {
      type: "divider",
    },

    {
      label: (
        <Text type={"danger"} onClick={handleConfirmDelete}>
          {t("delete")}
        </Text>
      ),
      key: "3",
      icon: <DeleteOutlined style={{ color: "#ff4d4f" }} />,
    },
  ];
  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }}>
        <Button type={"link"}>
          <Space>
            {t("action")}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      {contextHolder}
      {messageHolder}
      <DuplicateProjectModal
        isModalOpen={isModalOpen}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
      />
    </>
  );
};
export default ProjectMenu;
