import { FC, Key, useState } from "react";
import { Link, useParams } from "react-router-dom";
import OrganizationAuthorization from "../OrganizationAuthorization/OrganizationAuthorization";
import { Button, message, Space, Typography } from "antd";
import { useOrganization, useUser } from "@clerk/clerk-react";
import TaskTable from "../TaskTable/TaskTable";
import { getUserRole } from "../../utils/utilize";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_PROJECT_DETAIL,
  SEARCH_TASKS,
} from "../../apolloClient/queryConstraint";
import { Task, TaskSearchCondition } from "../../types/task";
import TaskSearchInput from "../TaskSearchInput/TaskSearchInput";
import { useTranslation } from "react-i18next";
import { ARCHIVE_TASKS } from "../../apolloClient/mutation";
import useProjectQuery from "../../hooks/useProjectQuery";
import { useRecoilState } from "recoil";
import { taskSearchCondition } from "../../recoil/atom";

const { Title, Text } = Typography;
type Props = {};
const TaskManagementScreen: FC<Props> = () => {
  const { organization } = useOrganization();
  const { user } = useUser();
  const [condition, setCondition] = useRecoilState(taskSearchCondition);

  const [messageApi, contextHolder] = message.useMessage();
  const [selected, setSelected] = useState<Key[]>([]);
  const [isArchiving, setIsArchiving] = useState(false);

  const { t } = useTranslation();

  const { projectId, projectSlug } = useParams();
  const { project } = useProjectQuery(projectId, projectSlug);

  const { data: taskQuery, loading: isTaskFetching } = useQuery(SEARCH_TASKS, {
    variables: {
      taskName: condition.taskName,
      taskStatus: condition.status,
      slug: `${projectId}/${projectSlug}`,
    },
  });

  const [archiveTasks, { error }] = useMutation(ARCHIVE_TASKS, {
    refetchQueries: [SEARCH_TASKS, GET_PROJECT_DETAIL],
  });

  const tasks: Task[] | undefined = taskQuery?.searchTask;

  const role = getUserRole({ user, organization });

  const handleChangeCondition = (condition: TaskSearchCondition) => {
    setCondition(condition);
  };

  const handleArchiveTasks = (keys: Key[]) => {
    setIsArchiving(true);
    archiveTasks({
      variables: {
        userUID: user?.id,
        taskNames: keys,
      },
    })
      .then(async () => {
        await messageApi.success({
          content: "Success",
        });
        setIsArchiving(false);
      })
      .catch(async () => {
        if (error) {
          await message.error({
            content: <>{t(error.graphQLErrors[0].extensions.code as string)}</>,
          });
        }
        setIsArchiving(false);
      });
  };
  return (
    <OrganizationAuthorization organization={organization}>
      {contextHolder}
      <Title level={3}>{t("tasks_management")}</Title>
      <Text
        style={{
          display: "block",
          marginBottom: 20,
        }}
      >
        {t("project_name")}: {project?.name}
      </Text>
      <Space>
        <Link
          to={`/organization/${organization?.id}/projects/${project?.slug}/tasks/create`}
        >
          <Button disabled={isArchiving}>{t("create_task")}</Button>
        </Link>
        <Link
          to={`/organization/${organization?.id}/projects/${project?.slug}/tasks/batch-create`}
        >
          <Button disabled={isArchiving}>{t("batch_create_task")}</Button>
        </Link>
      </Space>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TaskSearchInput
          onChangeCondition={handleChangeCondition}
          condition={condition}
          project={project}
        />
        <Button
          danger
          loading={isArchiving}
          disabled={isArchiving || !selected.length}
          onClick={() => handleArchiveTasks(selected)}
        >
          {t("archive")}
        </Button>
      </div>

      <TaskTable
        tasks={tasks}
        role={role}
        loading={isTaskFetching}
        project={project}
        setSelected={setSelected}
      />
    </OrganizationAuthorization>
  );
};
export default TaskManagementScreen;
