import { FC } from "react";
import { Avatar, Space, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
type Props = {
  src?: string | null;
  text?: string;
};

const { Text } = Typography;
const AvatarLabelGroup: FC<Props> = ({ src, text }) => {
  return (
    <Space align="baseline">
      <Avatar src={src} icon={!src ? <UserOutlined /> : null} />
      <Text>{text}</Text>
    </Space>
  );
};
export default AvatarLabelGroup;
