import { useQuery } from "@apollo/client";
import { GET_PROJECTS } from "../apolloClient/queryConstraint";
import { Project } from "../types/project";

const useProjectsQuery = (organizationSlug: string | undefined | null) => {
  const { data, loading } = useQuery(GET_PROJECTS, {
    variables: {
      organizationSlug,
    },
  });

  return {
    loading,
    projects: data?.organizationDetail.projects as Project[] | undefined,
  };
};

export default useProjectsQuery;
