import { OrganizationResource } from "@clerk/types/dist/organization";
import { useEffect, useState } from "react";
import { OrganizationMembershipResource } from "@clerk/types/dist/organizationMembership";

export type UserData = {
  publicUserData: OrganizationMembershipResource["publicUserData"];
  role: OrganizationMembershipResource["role"];
};
export default function useUsers(organization?: OrganizationResource | null) {
  const [users, setUsers] = useState<UserData[]>();

  useEffect(() => {
    organization?.getMemberships().then((membershipResource) => {
      const usersData = membershipResource.map(({ publicUserData, role }) => ({
        publicUserData,
        role,
      }));
      setUsers(usersData);
    });
  }, [organization?.id]);

  return users;
}
