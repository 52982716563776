import { FC, useState } from "react";
import { Task } from "../../types/task";
import { Project } from "../../types/project";
import {
  Button,
  Col,
  message,
  Row,
  Space,
  Steps,
  Timeline,
  Typography,
} from "antd";
import StatusTag from "../StatusTag/StatusTag";
import StatusNavigationButtons from "../StatusNavigationButtons/StatusNavigationButtons";
import { useMutation } from "@apollo/client";
import { UPDATE_TASK_STATUS_BY_STEP } from "../../apolloClient/mutation";
import { useUser } from "@clerk/clerk-react";
import { GET_TASK_DETAIL } from "../../apolloClient/queryConstraint";
import { renderHistoryByType } from "../../utils/renderHistoryByType";
import { OrganizationResource } from "@clerk/types/dist/organization";
import useUsers from "../../hooks/useUsers";
import { getCurrentStatus } from "../../utils/utilize";
import { useTranslation } from "react-i18next";

type Props = {
  organization?: OrganizationResource | null;
  currentTask: Task;
  currentProject: Project;
};

const { Title } = Typography;

const WorkerTaskDetailScreen: FC<Props> = ({
  currentTask,
  currentProject,
  organization,
}) => {
  const { user } = useUser();
  const users = useUsers(organization);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [updateTaskStatus, { error }] = useMutation(UPDATE_TASK_STATUS_BY_STEP);
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
  const handleStateChange = async (newState: string): Promise<boolean> => {
    setIsUpdatingStatus(true);
    return updateTaskStatus({
      variables: {
        status: newState,
        taskId: currentTask.id,
        userUid: user?.id,
      },
      refetchQueries: [GET_TASK_DETAIL],
    })
      .then(() => {
        setIsUpdatingStatus(false);
        messageApi.success({
          content: "ステータスを変更しました。",
        });
        return true;
      })
      .catch(() => {
        if (error)
          messageApi.error({
            content: <>{t(error.graphQLErrors[0].extensions.code as string)}</>,
          });
        setIsUpdatingStatus(false);
        return false;
      });
  };
  const currentNode = getCurrentStatus(currentTask.status, currentProject);

  return (
    <div style={{ overflowY: "scroll", height: "calc(100vh - 150px)" }}>
      {contextHolder}
      <Row
        style={{
          height: "calc(100vh - 150px)",
        }}
        align="middle"
        justify="center"
      >
        {currentProject.setting.showStatusStep && (
          <Steps
            size="small"
            progressDot
            style={{
              position: "fixed",
              top: 64,
              left: 0,
              maxWidth: "1000px",
              padding: "20px",
              background: "white",
              zIndex: 100,
            }}
            current={currentProject.blueprint.nodes.findIndex(
              ({ name }) => name === currentTask.status
            )}
            items={currentProject.blueprint.nodes.map(({ label }) => ({
              title: label,
            }))}
          />
        )}
        <Col>
          <Space direction="vertical" align="center">
            <StatusTag
              taskStatus={currentTask.status}
              step={currentProject.blueprint.nodes}
            />
            <Title
              level={3}
              copyable
              style={{
                marginTop: 5,
              }}
            >
              {currentTask.name}
            </Title>
            {currentNode?.buttons?.map(({ label, action, name }, index) => (
              <Button type={name} key={index}>
                <a href={action} target="_blank" rel="noreferrer">
                  {label}
                </a>
              </Button>
            ))}
          </Space>
        </Col>
        <StatusNavigationButtons
          organization={organization}
          isButtonDisabled={isUpdatingStatus}
          onStateChange={handleStateChange}
          project={currentProject}
          currentStatus={currentTask.status}
        />
      </Row>
      <Row
        align="middle"
        justify="center"
        style={{
          backgroundColor: "white",
        }}
      >
        <Col
          span={22}
          style={{
            padding: 40,
            border: "1px solid #ccc",
            borderRadius: 10,
          }}
        >
          <Timeline
            items={renderHistoryByType(
              currentTask.history,
              currentProject,
              users
            )}
          />
        </Col>
      </Row>
    </div>
  );
};
export default WorkerTaskDetailScreen;
