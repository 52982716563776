import { useState } from "react";
import debounce from "lodash.debounce";

type UseDebouncedInputChangeProps<T> = {
  initialValue: T;
  delay?: number;
  onChange: (value: T) => void;
};
export default function useDebouncedInput<T>({
  initialValue,
  delay = 500,
  onChange,
}: UseDebouncedInputChangeProps<T>) {
  const [inputValue, setInputValue] = useState(initialValue);

  const debouncedOnChange = debounce((value: T) => {
    onChange(value);
  }, delay);

  const handleInputChange = (value: T) => {
    setInputValue(value);
    debouncedOnChange(value);
  };

  return {
    inputValue,
    handleInputChange,
  };
}
