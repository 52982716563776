import { FC } from "react";
import { Button, Form, Input, Modal, Space } from "antd";
import { useTranslation } from "react-i18next";

type Props = {
  isModalOpen: boolean;
  isSubmitting: boolean;
  handleSubmit: (value: any) => void;
  handleCancel: () => void;
};
const DuplicateProjectModal: FC<Props> = ({
  isModalOpen,
  isSubmitting,
  handleCancel,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
      title={t("duplicate_project")}
    >
      <Form
        layout={"vertical"}
        style={{ maxWidth: 600 }}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Form.Item
          label={t("project_name")}
          name="project_name"
          rules={[
            { required: true, message: "Please input project name!" },
            { min: 3, message: "Required at least 3 characters" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("project_slug")}
          name="project_slug"
          rules={[
            { required: true, message: "Please input project name!" },
            { min: 3, message: "Required at least 3 characters" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Space
            align="center"
            size={"middle"}
            style={{ justifyContent: "end" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {t("submit")}
            </Button>
            <Button
              htmlType="button"
              onClick={handleCancel}
              disabled={isSubmitting}
            >
              {t("cancel")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default DuplicateProjectModal;
