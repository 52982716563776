import { FC, useMemo, useState } from "react";
import { Bar } from "@ant-design/plots";
import OrganizationAuthorization from "../OrganizationAuthorization/OrganizationAuthorization";
import { useOrganization } from "@clerk/clerk-react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import { useParams } from "react-router-dom";
import HistoryTable from "../Table/TaskHistoryTable/Table";
import StatusCountByUserTable from "../Table/StatusCountByUserTable/StatusCountByUserTable";
import dayjs from "../../../src/day";
import HistoryExportModal from "../HistoryExportModal/HistoryExportModal";
import {
  downloadCSV,
  ExportOptions,
  getStatusEntities,
} from "../../utils/utilize";
import { ENCODE_TYPE } from "../../enum/ENCODE_TYPE";
import { HISTORY_TYPE } from "../../types/history";
import { useTranslation } from "react-i18next";
import type { RangeValue } from "rc-picker/lib/interface";
import useProjectQuery from "../../hooks/useProjectQuery";
import useTaskAnalytics from "../../hooks/useTaskAnalytics";
import useExportCSVQuery from "../../hooks/useExportCSVQuery";

type Props = {};

const { Title } = Typography;

const AnalyticsScreen: FC<Props> = () => {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const { projectId, projectSlug } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState<boolean>(false);
  const [exportOptions, setExportOptions] = useState<ExportOptions>({
    encode: ENCODE_TYPE.SHIFT_JIS,
    type: HISTORY_TYPE.CREATE,
    range: {
      startDate: dayjs(new Date()).utc().format("YYYY-MM-DD"),
      endDate: dayjs(new Date()).utc().format("YYYY-MM-DD"),
    },
  });

  const [rangeValue, setRangeValue] = useState<RangeValue<dayjs.Dayjs>>([
    dayjs().startOf("month"),
    dayjs(),
  ]);

  const { project } = useProjectQuery(projectId, projectSlug);

  const {
    analyticsResult,
    loading: isFetchingTaskAnalytics,
    handleRefetch,
  } = useTaskAnalytics(`${projectId}/${projectSlug}`);

  const { exportData, error } = useExportCSVQuery(
    {
      startDate: exportOptions.range?.startDate,
      endDate: exportOptions.range?.endDate,
    },
    exportOptions.type,
    `${projectId}/${projectSlug}`
  );

  const data = useMemo(
    () =>
      analyticsResult?.map(({ status, count }) => ({
        status: getStatusEntities(project?.blueprint.nodes, status)?.label,
        value: count,
      })) || [],
    [analyticsResult, project?.blueprint.nodes]
  );
  const config = {
    data,
    xField: "value",
    yField: "status",
    seriesField: "status",
    legend: {
      position: "top-left" as const,
    },
    color: project?.blueprint.nodes.map(({ color }) => color),
    label: {
      position: "middle" as const,
    },
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeOption = (options: ExportOptions) => {
    setExportOptions(options);
  };
  const handleExport = async () => {
    if (!exportData && !error) {
      await messageApi.info({ content: "There is nothing to export" });
    } else {
      const errorMsg = error ? "Error getting CSV Result" : null;
      if (exportData) {
        downloadCSV({ obj: exportData, encodeType: exportOptions.encode });
        handleClose();
      }
      if (errorMsg) {
        await messageApi.error({ content: errorMsg });
      }
    }
  };

  return (
    <OrganizationAuthorization organization={organization}>
      {contextHolder}
      <Title>
        {t("project")}: {project?.name}
      </Title>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title>{t("overview")}</Title>
        <Button type="primary" size="large" onClick={handleRefetch}>
          {t("refresh")}
        </Button>
      </div>
      <Title level={3}>{t("status_of_all_tasks")}</Title>
      <Row>
        <Col span={22}>
          <Bar
            {...config}
            loading={isFetchingTaskAnalytics}
            label={{
              position: (data) => {
                return data.value > 0 ? "middle" : "right";
              },
              style: {
                fill: "#fff",
              },
            }}
          />
        </Col>
      </Row>

      <Title level={2}>{t("user_analytics")}</Title>
      <Space align={"baseline"}>
        <Title level={4}>{t("action_per_user")}</Title>
        <DatePicker.RangePicker
          defaultValue={[dayjs().startOf("month"), dayjs()]}
          value={rangeValue}
          onChange={(value) => setRangeValue(value)}
        />
      </Space>
      <StatusCountByUserTable project={project} range={rangeValue} />

      <Row style={{ margin: "50px 0 20px" }}>
        <Col span={12}>
          <Title level={2} style={{ margin: 0 }}>
            {t("recent_task_history")}
          </Title>
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            size="large"
            style={{
              alignSelf: "baseline",
            }}
            onClick={handleOpen}
          >
            {t("export_all_history")}
          </Button>
          <HistoryExportModal
            isOpen={open}
            handleClose={handleClose}
            exportOptions={exportOptions}
            onChangeOptions={handleChangeOption}
            onExport={handleExport}
          />
        </Col>
      </Row>
      {project && (
        <HistoryTable project={project} histories={project?.history} />
      )}
    </OrganizationAuthorization>
  );
};
export default AnalyticsScreen;
