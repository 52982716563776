import { FC } from "react";
import { Select } from "antd";
import { Project } from "../../types/project";
import { getStatusEntities } from "../../utils/utilize";

type Props = {
  loading?: boolean;
  currentProject: Project;
  currentStatus: string;
  handleChangeStatus: (newStatus: string) => void;
};

const { Option } = Select;
const StatusSelection: FC<Props> = ({
  currentProject,
  currentStatus,
  handleChangeStatus,
  loading = false,
}) => {
  const {
    blueprint: { nodes },
  } = currentProject;

  return (
    <Select
      dropdownStyle={{
        minWidth: "fit-content",
      }}
      loading={loading}
      disabled={loading}
      bordered={false}
      defaultValue={getStatusEntities(nodes, currentStatus)?.label}
      onChange={handleChangeStatus}
    >
      {nodes.map(({ label, name }, index) => (
        <Option key={index} value={name}>
          {label}
        </Option>
      ))}
    </Select>
  );
};
export default StatusSelection;
