import { FC, useState } from "react";
import {
  Button,
  Col,
  message,
  Row,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_TASKS_FROM_CSV } from "../../apolloClient/mutation";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import useUpload from "../../hooks/useUpload";
import { Trans, useTranslation } from "react-i18next";
import {
  GET_PROJECT_DETAIL,
  SEARCH_TASKS,
} from "../../apolloClient/queryConstraint";
import { useRecoilValue } from "recoil";
import { taskSearchCondition } from "../../recoil/atom";

type Props = {};

const { Dragger } = Upload;

const { Title, Paragraph } = Typography;

const BatchCreationScreen: FC<Props> = () => {
  const { t } = useTranslation();
  const searchCondition = useRecoilValue(taskSearchCondition);
  const { projectId, projectSlug, organizationId } = useParams();
  const nav = useNavigate();
  const { user } = useUser();
  const [isCreating, setIsCreating] = useState(false);
  const [createTasks, { error }] = useMutation(CREATE_TASKS_FROM_CSV);
  const { refetch } = useQuery(SEARCH_TASKS, {
    variables: {
      taskName: searchCondition.taskName,
      taskStatus: searchCondition.status,
      slug: `${projectId}/${projectSlug}`,
    },
  });

  const { handleUpload, downloadUrl } = useUpload();

  const props: UploadProps = {
    name: "file",
    multiple: false,
    // todo: We will need to write the upload process ourselves later using customRequest.
    action: "https://52ab279b-57d3-40a1-8fe7-4e6e172f5df1.mock.pstmn.io/upload",
    async onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        await handleUpload(info.file.originFileObj as File);
        await message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        await message.error(`${info.file.name} file upload failed.`);
      }
    },
    async onDrop(e) {
      await handleUpload(e.dataTransfer.files[0]);
    },
  };

  const handleCreateTasks = () => {
    if (!!downloadUrl) {
      setIsCreating(true);
      createTasks({
        variables: {
          slug: `${projectId}/${projectSlug}`,
          userUID: user?.id,
          url: downloadUrl,
        },
        refetchQueries: [GET_PROJECT_DETAIL],
      })
        .then(() => {
          refetch().then(() => {
            nav(
              `/organization/${organizationId}/projects/${projectId}/${projectSlug}/tasks-management`
            );
          });
        })
        .catch(async () => {
          if (error)
            message.error({
              content: (
                <Trans
                  i18nKey={error.graphQLErrors[0].extensions.code as string}
                  values={{
                    task_names: error.graphQLErrors[0].extensions.metadata,
                  }}
                />
              ),
            });
          setIsCreating(false);
        });
    } else return message.info("Missing file");
  };
  return (
    <Row gutter={20} justify="center">
      <Col span={20}>
        <Title level={2}>{t("batch_creation")}</Title>
        <Dragger
          {...props}
          style={{
            marginTop: 30,
          }}
        >
          <Paragraph>
            <InboxOutlined />
          </Paragraph>
          <Paragraph>{t("click_or_drag")}</Paragraph>
          <Paragraph>{t("batch_description")}</Paragraph>
        </Dragger>
        <Button
          style={{
            marginTop: 20,
          }}
          disabled={isCreating || !downloadUrl}
          type="primary"
          loading={isCreating}
          block
          onClick={handleCreateTasks}
        >
          {t("create")}
        </Button>
      </Col>
    </Row>
  );
};
export default BatchCreationScreen;
