import { useQuery } from "@apollo/client";
import { TASK_ANALYTICS } from "../apolloClient/queryConstraint";
import { Analytics } from "../types/analytics";
import { useEffect, useState } from "react";

const useTaskAnalytics = (projectSlug: string) => {
  const { data, loading, refetch } = useQuery(TASK_ANALYTICS, {
    variables: {
      projectSlug,
    },
  });

  const [analyticsResult, setAnalyticsResult] = useState<
    Analytics[] | undefined
  >();

  const [isFetching, setIsFetching] = useState(false);

  const handleRefetch = () => {
    setIsFetching(true);
    refetch({ projectSlug })
      .then(({ data: { taskAnalytics } }) => {
        setAnalyticsResult(taskAnalytics);
        setIsFetching(false);
      })
      .catch(() => setIsFetching(false));
  };

  useEffect(() => {
    setAnalyticsResult(data?.taskAnalytics);
  }, [data?.taskAnalytics]);

  return {
    loading: isFetching || loading,
    analyticsResult,
    handleRefetch,
  };
};

export default useTaskAnalytics;
