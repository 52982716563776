import { ComponentType, FC } from "react";
import {
  ApolloClient,
  ApolloProvider as GraphQlProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { useAuth } from "@clerk/clerk-react";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";

const withApolloClient = <P extends object>(
  WrappedComponent: ComponentType<P>
) => {
  const ApolloProvider: FC<P> = (props) => {
    const { getToken } = useAuth();
    const httpLink = createHttpLink({
      uri:
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_LOCAL_GRAPHQL
          : process.env.REACT_APP_PUBLIC_GRAPHQL,
    });

    const authLink = setContext(async (_, { headers }) => {
      console.log(headers, "----");
      const userToken = await getToken();
      return {
        headers: {
          ...headers,
          authorization: !!userToken ? `Bearer ${userToken}` : "",
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          Sentry.captureException(
            new Error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        });
      }

      if (networkError) {
        Sentry.captureException(new Error(`[Network error]: ${networkError}`));
      }
    });

    const apolloClient = new ApolloClient({
      link: errorLink.concat(authLink.concat(httpLink)),
      cache: new InMemoryCache(),
    });

    return (
      <GraphQlProvider client={apolloClient}>
        <WrappedComponent {...props} />
      </GraphQlProvider>
    );
  };
  return ApolloProvider;
};

export default withApolloClient;
