import { History } from "../types/history";
import { TimelineItemProps } from "antd";
import StatusTag from "../components/StatusTag/StatusTag";
import { Project } from "../types/project";
import { UserData } from "../hooks/useUsers";
import { getUserDataById } from "./utilize";
import { Trans } from "react-i18next";
import dayjs from "dayjs";

export const renderHistoryByType = (
  history: History[] = [],
  project?: Project,
  users?: UserData[]
): TimelineItemProps[] => {
  return history.map(({ type, createdBy, metadata, createdAt, user }) => {
    switch (type) {
      case "CREATE":
        const userData = getUserDataById(users, createdBy);
        return {
          children: (
            <Trans
              i18nKey="history_created"
              values={{
                user_name: `${userData?.publicUserData.firstName} ${userData?.publicUserData.lastName}`,
                time: `${dayjs(createdAt).format("YYYY-MM-DD[ ]HH:mm")}`,
              }}
            />
          ),
        };
      case "CHANGE_STATUS":
        return {
          children: (
            <Trans
              i18nKey="history_change_status"
              components={{
                previous: (
                  <StatusTag
                    taskStatus={metadata?.beforeStatus as string}
                    step={project?.blueprint.nodes}
                  />
                ),
                before: (
                  <StatusTag
                    taskStatus={metadata?.afterStatus as string}
                    step={project?.blueprint.nodes}
                  />
                ),
              }}
              values={{
                user_name: user?.name,
                time: `${dayjs(createdAt).format("YYYY-MM-DD[ ]HH:mm")}`,
              }}
            />
          ),
        };

      default:
        return { children: type };
    }
  });
};
