import { useQuery } from "@apollo/client";
import { GET_PROJECT_DETAIL } from "../apolloClient/queryConstraint";
import { Project } from "../types/project";

const useProjectQuery = (
  projectId: string | undefined,
  projectSlug: string | undefined
) => {
  const { data, loading } = useQuery(GET_PROJECT_DETAIL, {
    variables: {
      slug: `${projectId}/${projectSlug}`,
    },
  });

  return {
    loading,
    project: data?.projectDetail as Project | undefined,
  };
};

export default useProjectQuery;
