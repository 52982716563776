import { FC, useEffect, useState } from "react";
import { Button, List, message, Popconfirm, Space, Typography } from "antd";
import Box from "../Box/Box";
import GraphEditingForm from "../GraphEditingForm/GraphEditingForm";
import { useTranslation } from "react-i18next";
import { Connection, Project, Step } from "../../types/project";
import AddConnectionsForm from "../AddConnectionsForm/AddConnectionsForm";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useMutation } from "@apollo/client";
import { UPDATE_PROJECT_BLUEPRINT } from "../../apolloClient/mutation";

type Props = {
  currentStatus?: Step;
  currentProject?: Project;
  currentGraphIndex: number | null;
  handleChangeGraphIndex: (index: number) => void;
  handleCancelChangeGraphIndex: () => void;
  handleSubmitGraphChange: (
    values: Record<string, string>,
    index: number
  ) => void;
  handleDeleteButton: (currentGraph: Connection, index: number) => void;
};

const { Title, Text, Paragraph } = Typography;
const ButtonsSetting: FC<Props> = ({
  currentStatus,
  currentProject,
  currentGraphIndex,
  handleChangeGraphIndex,
  handleSubmitGraphChange,
  handleCancelChangeGraphIndex,
  handleDeleteButton,
}) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<Connection[] | undefined>();
  const [updateProjectBlueprint, { error }] = useMutation(
    UPDATE_PROJECT_BLUEPRINT
  );

  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [isReordering, setIsReordering] = useState(false);
  const [isCreatingNewButton, setIsCreatingNewButton] =
    useState<boolean>(false);
  const onDragEnd = (result: DropResult) => {
    if (dataSource) {
      if (!result.destination) return;

      const reorderedItems = Array.from(dataSource);
      const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, reorderedItem);
      setDataSource(reorderedItems);

      const orderChanged = !dataSource.every(
        (item, index) => item === reorderedItems[index]
      );
      setIsOrderChanged(orderChanged);
    }
  };

  const handleReorder = (connections: Connection[]) => {
    const updatedBlueprint = {
      ...currentProject?.blueprint,
      graph: connections,
    };

    setIsReordering(true);
    updateProjectBlueprint({
      variables: {
        slug: currentProject?.slug,
        blueprint: updatedBlueprint,
      },
    })
      .then(async () => {
        message.success({
          content: t("reorder_successfully"),
        });
        setIsReordering(false);
      })
      .catch(async () => {
        message.error({
          content: <>{t(error?.graphQLErrors[0].extensions.code as string)}</>,
        });
        setIsReordering(false);
      });
  };

  useEffect(() => {
    setDataSource(
      currentProject?.blueprint.graph.filter(
        ({ from }) => from === currentStatus?.name
      )
    );
  }, [currentProject?.blueprint.graph, currentStatus?.name]);
  return (
    <div
      style={{
        margin: "20px 0",
      }}
    >
      {isCreatingNewButton ? (
        <Box>
          <Title
            style={{
              margin: 0,
            }}
          >
            {t("add_connection")}
          </Title>
          <Text type={"secondary"}>{t("add_connection_for_this_status")}</Text>
          <AddConnectionsForm
            handleCancelAdding={() => setIsCreatingNewButton(false)}
            showCancelButton
            currentProject={currentProject}
            currentStatus={currentStatus}
          />
        </Box>
      ) : !!dataSource?.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            isDropDisabled={
              isCreatingNewButton ||
              !!currentGraphIndex ||
              currentGraphIndex === 0
            }
            droppableId="list"
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <List
                  header={
                    <Space size={20} align="baseline">
                      <Title level={3}>{t("buttons")}</Title>
                      {isCreatingNewButton ? null : (
                        <Button onClick={() => setIsCreatingNewButton(true)}>
                          {t("create_new_transition_button")}
                        </Button>
                      )}
                      <Button
                        type="primary"
                        disabled={isReordering || !isOrderChanged}
                        loading={isReordering}
                        onClick={() => handleReorder(dataSource)}
                      >
                        {t("save")}
                      </Button>
                    </Space>
                  }
                  dataSource={dataSource}
                  renderItem={(graph, index) => (
                    <Draggable
                      isDragDisabled={
                        !!currentGraphIndex || currentGraphIndex === 0
                      }
                      key={index.toString()}
                      draggableId={index.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <List.Item
                          ref={provided.innerRef}
                          actions={[
                            <>
                              {currentGraphIndex === index ? null : (
                                <Button
                                  onClick={() => handleChangeGraphIndex(index)}
                                >
                                  {t("edit")}
                                </Button>
                              )}
                            </>,
                            <>
                              {currentGraphIndex === index ? null : (
                                <Popconfirm
                                  title="Delete button"
                                  description="Are you sure to delete this button?"
                                  okText="Yes"
                                  cancelText="No"
                                  onConfirm={() =>
                                    handleDeleteButton(graph, index)
                                  }
                                >
                                  <Button danger>{t("delete")}</Button>
                                </Popconfirm>
                              )}
                            </>,
                          ]}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            background: "white",
                            ...provided.draggableProps.style,
                          }}
                        >
                          {currentGraphIndex === index &&
                          !!currentProject?.blueprint.graph ? (
                            <Box>
                              <GraphEditingForm
                                nodes={currentProject.blueprint.nodes}
                                currentGraph={graph}
                                handleSubmit={handleSubmitGraphChange}
                                currentIndex={currentGraphIndex}
                                handleCancel={handleCancelChangeGraphIndex}
                              />
                            </Box>
                          ) : (
                            <List.Item.Meta
                              title={
                                <Text>
                                  {t("button_label")}: {graph.label}
                                </Text>
                              }
                              description={
                                <Paragraph>
                                  {t("move_to")}: {graph.to}
                                </Paragraph>
                              }
                            />
                          )}
                        </List.Item>
                      )}
                    </Draggable>
                  )}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Box>
          <Title
            style={{
              margin: 0,
            }}
          >
            {t("add_connection")}
          </Title>
          <Text type={"secondary"}>{t("add_connection_for_this_status")}</Text>
          <AddConnectionsForm
            currentProject={currentProject}
            currentStatus={currentStatus}
          />
        </Box>
      )}
    </div>
  );
};
export default ButtonsSetting;
