import { FC } from "react";
import { Button, ConfigProvider, ThemeConfig } from "antd";
import { convertHexToRGB } from "../../utils/utilize";
type Props = {
  colorBgContainer: string;
  label: string;
  disabled?: boolean;
  onClick: () => void;
};
const StatusTransitionButton: FC<Props> = ({
  colorBgContainer,
  label,
  disabled = false,
  onClick,
}) => {
  const config: ThemeConfig = {
    components: {
      Button: {
        colorPrimaryBg: colorBgContainer,
        colorPrimaryHover: convertHexToRGB(colorBgContainer),
        colorPrimaryActive: convertHexToRGB(colorBgContainer),
        colorPrimaryBorderHover: convertHexToRGB(colorBgContainer),
        colorPrimaryBorder: convertHexToRGB(colorBgContainer),
        colorPrimaryText: convertHexToRGB(colorBgContainer),
        colorPrimaryBgHover: convertHexToRGB(colorBgContainer),
        colorPrimaryTextHover: convertHexToRGB(colorBgContainer),
        colorPrimaryTextActive: convertHexToRGB(colorBgContainer),
        colorPrimary: convertHexToRGB(colorBgContainer, "0.7"),
        colorBgContainerDisabled: convertHexToRGB(colorBgContainer, "0.5"),
        colorBorder: convertHexToRGB(colorBgContainer, "0.5"),
      },
    },
  };

  return (
    <ConfigProvider theme={colorBgContainer !== "#FFFFFF" ? config : undefined}>
      <Button
        onClick={onClick}
        style={{
          boxShadow: `${convertHexToRGB(colorBgContainer, "0.5")} 0px 5px 15px`,
        }}
        type={colorBgContainer !== "#FFFFFF" ? "primary" : "default"}
        disabled={disabled}
      >
        {label}
      </Button>
    </ConfigProvider>
  );
};
export default StatusTransitionButton;
