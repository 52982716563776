import { FC, useState } from "react";
import { Button, ColorPicker, Form, Input, message, Select, Space } from "antd";
import { useTranslation } from "react-i18next";
import { Connection, Project, Step } from "../../types/project";
import { useMutation } from "@apollo/client";
import { UPDATE_PROJECT_BLUEPRINT } from "../../apolloClient/mutation";
import { useParams } from "react-router-dom";
import { GET_PROJECT_DETAIL } from "../../apolloClient/queryConstraint";
import { Color } from "antd/es/color-picker";

type Props = {
  currentProject?: Project;
  currentStatus?: Step;
  showCancelButton?: boolean;
  handleCancelAdding?: () => void;
};
const AddConnectionsForm: FC<Props> = ({
  currentProject,
  currentStatus,
  showCancelButton = false,
  handleCancelAdding,
}) => {
  const { projectId, projectSlug } = useParams();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [updateProjectBlueprint, { error }] = useMutation(
    UPDATE_PROJECT_BLUEPRINT
  );
  const [messageApi, contextHolder] = message.useMessage();
  const toOptions = currentProject?.blueprint.nodes.map(({ name }) => ({
    label: name,
    value: name,
  }));

  const handleSubmit = (values: Record<string, string | Color>) => {
    if (currentProject) {
      const newGraph = {
        from: currentStatus?.name,
        to: values.to,
        label: values.graph_label,
        color:
          typeof values.color === "string"
            ? values.color
            : values.color.toHexString(),
      } as Connection;

      const updatedGraphs = [...currentProject.blueprint.graph, newGraph];

      setIsSubmitting(true);
      updateProjectBlueprint({
        variables: {
          slug: `${projectId}/${projectSlug}`,
          blueprint: {
            ...currentProject.blueprint,
            graph: updatedGraphs,
          },
        },
        refetchQueries: [GET_PROJECT_DETAIL],
      })
        .then(async () => {
          if (handleCancelAdding) {
            handleCancelAdding();
          }
          setIsSubmitting(false);
          await messageApi.success({
            content: "Create connection successfully",
          });
        })
        .catch(async () => {
          if (error)
            message.error({
              content: (
                <>{t(error.graphQLErrors[0].extensions.code as string)}</>
              ),
            });
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        labelCol={{ span: 2 }}
        style={{
          marginTop: 20,
        }}
        onFinish={handleSubmit}
      >
        <Form.Item label={t("status_name")}>
          <Input disabled value={currentStatus?.name} />
        </Form.Item>
        <Form.Item label={t("button_label")} name={"graph_label"}>
          <Input placeholder={t("input_button_label")} />
        </Form.Item>
        <Form.Item label={t("transit_to")} name={"to"}>
          <Select
            placeholder={t("select_to")}
            key={"select_to"}
            options={toOptions}
          />
        </Form.Item>

        <Form.Item label={t("color")} name={"color"} initialValue={"#FFFFFF"}>
          <ColorPicker
            presets={[
              { label: "Recommend", colors: ["#86CB3C", "#E31B0C", "#FFFFFF"] },
            ]}
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 2,
          }}
        >
          <Space>
            <Button
              type="primary"
              disabled={isSubmitting}
              loading={isSubmitting}
              htmlType="submit"
            >
              {t("submit")}
            </Button>
            {showCancelButton && (
              <Button onClick={handleCancelAdding}>{t("cancel")}</Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};
export default AddConnectionsForm;
