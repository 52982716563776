import { FC } from "react";
import { Button, ColorPicker, Form, Input, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import { Color } from "antd/es/color-picker";
import { Blueprint } from "../../types/project";

type Props = {
  blueprint: Blueprint;
  handleSubmit: (values: Record<string, string | Color>) => void;
  onCancel: () => void;
  isOpen: boolean;
  isSubmitting: boolean;
};

const AddStatusModal: FC<Props> = ({
  blueprint,
  handleSubmit,
  isOpen,
  onCancel,
  isSubmitting,
}) => {
  const { t } = useTranslation();

  const fromOption = blueprint.nodes.map(({ name }) => ({
    label: name,
    value: name,
    disabled: name === "completed",
  }));
  const toOptions = blueprint.nodes.map(({ name }) => ({
    label: name,
    value: name,
  }));

  const graphLabelOptions = [
    {
      label: t("次へ"),
      value: "次へ",
    },
    {
      label: t("戻る"),
      value: "戻る",
    },
  ];
  return (
    <Modal
      footer={null}
      title={t("add_status")}
      open={isOpen}
      onCancel={onCancel}
    >
      <Form
        wrapperCol={{
          offset: 2,
        }}
        initialValues={{
          color: "#1677FF",
        }}
        labelCol={{ span: 8 }}
        onFinish={handleSubmit}
      >
        <Form.Item
          required
          rules={[{ required: true, message: "This field is required" }]}
          label={t("button_label")}
          name={"graph_label"}
        >
          <Select
            placeholder={t("select_button_label")}
            options={graphLabelOptions}
          />
        </Form.Item>
        <Form.Item
          required
          rules={[{ required: true, message: "This field is required" }]}
          label={t("status_label")}
          name={"node_label"}
        >
          <Input placeholder={t("input_status_label")} />
        </Form.Item>
        <Form.Item
          required
          rules={[{ required: true, message: "This field is required" }]}
          label={t("status_name")}
          name={"node_name"}
        >
          <Input placeholder={t("input_status_name")} />
        </Form.Item>
        <Form.Item
          required
          rules={[{ required: true, message: "This field is required" }]}
          label={t("before_transit")}
          name={"previous"}
        >
          <Select
            placeholder={t("select_previous")}
            key={"select_previous"}
            options={fromOption}
          />
        </Form.Item>
        <Form.Item
          required
          rules={[{ required: true, message: "This field is required" }]}
          label={t("transit_to")}
          name={"to"}
        >
          <Select
            placeholder={t("select_to")}
            key={"select_to"}
            options={toOptions}
          />
        </Form.Item>

        <Form.Item
          required
          rules={[{ required: true, message: "This field is required" }]}
          label={t("color")}
          name={"color"}
        >
          <ColorPicker />
        </Form.Item>

        <Form.Item
          style={{
            marginBottom: 0,
          }}
        >
          <div
            style={{
              justifyContent: "end",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {t("submit")}
            </Button>
            <Button
              htmlType="button"
              onClick={onCancel}
              disabled={isSubmitting}
            >
              {t("cancel")}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AddStatusModal;
