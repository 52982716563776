import { FC } from "react";
import { Button, List, Typography } from "antd";
import { Link } from "react-router-dom";
import { OrganizationResource } from "@clerk/types/dist/organization";
import { Project } from "../../types/project";
import { useTranslation } from "react-i18next";
import RoleBasedVisibility from "../RoleBasedVisibility/RoleBasedVisibility";
import { MembershipRole } from "@clerk/types/dist/organizationMembership";
import ProjectMenu from "../ProjectMenu/ProjectMenu";

type Props = {
  projects?: Project[];
  organization?: OrganizationResource | null;
  role?: MembershipRole;
  loading?: boolean;
};

const { Paragraph } = Typography;
const ProjectList: FC<Props> = ({
  organization,
  projects,
  role = "basic_member",
  loading = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <List
        loading={loading}
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={projects}
        renderItem={(item) => (
          <List.Item
            actions={[
              <RoleBasedVisibility
                viewable={["admin"]}
                viewer={role}
                fallback={
                  <Link
                    to={`/organization/${organization?.id}/projects/${item.slug}`}
                  >
                    <Button>{t("start")}</Button>
                  </Link>
                }
              >
                <ProjectMenu
                  organization={organization}
                  currentProject={item}
                />
              </RoleBasedVisibility>,
            ]}
          >
            <List.Item.Meta
              title={item.name}
              description={
                <Paragraph>
                  {t("task_count")}: {item.tasks?.length}
                </Paragraph>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};
export default ProjectList;
