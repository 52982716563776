import { FC, useState } from "react";
import { Button, Col, Form, message, Row, Typography } from "antd";
import { convertSubmittedTasksNameToArray } from "../../utils/utilize";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_TASKS } from "../../apolloClient/mutation";
import { useUser } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GET_PROJECT_DETAIL,
  SEARCH_TASKS,
} from "../../apolloClient/queryConstraint";
import FormFields from "../FormFields/FormFields";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { taskSearchCondition } from "../../recoil/atom";

type Props = {};

const { Title } = Typography;
const TaskCreationScreen: FC<Props> = () => {
  const { projectId, projectSlug, organizationId } = useParams();
  const [createTasks, { error }] = useMutation(CREATE_TASKS);
  const searchCondition = useRecoilValue(taskSearchCondition);
  const { user } = useUser();
  const nav = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { refetch } = useQuery(SEARCH_TASKS, {
    variables: {
      taskName: searchCondition.taskName,
      taskStatus: searchCondition.status,
      slug: `${projectId}/${projectSlug}`,
    },
  });
  const onFinish = (
    values: Record<string, string | Record<string, string>[]>
  ) => {
    const submittedValues = convertSubmittedTasksNameToArray(values);
    setIsSubmitting(true);
    createTasks({
      variables: {
        taskNames: submittedValues,
        userUID: user?.id,
        projectSlug: `${projectId}/${projectSlug}`,
      },
      refetchQueries: [GET_PROJECT_DETAIL],
    })
      .then(async () => {
        messageApi.success({
          content: `Successfully created ${submittedValues}`,
        });
        refetch().then(() => {
          nav(
            `/organization/${organizationId}/projects/${projectId}/${projectSlug}/tasks-management`
          );
        });
      })
      .catch(async () => {
        if (error)
          await messageApi.error({
            content: <>{t(error.graphQLErrors[0].extensions.code as string)}</>,
          });
        setIsSubmitting(false);
      });
  };
  const { t } = useTranslation();
  return (
    <Row justify="center">
      {contextHolder}
      <Col span={12}>
        <Title level={2}>{t("task_creation")}</Title>
        <Form
          disabled={isSubmitting}
          style={{
            border: "1px solid #ccccc1",
            padding: 40,
            borderRadius: "10px",
            maxWidth: "1000px",
          }}
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.List name="task_name_list">
            {(fields, operation) => {
              return (
                <FormFields
                  fields={fields}
                  operation={operation}
                  isSubmitting={isSubmitting}
                />
              );
            }}
          </Form.List>
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
          >
            <Button
              block
              type="primary"
              size="large"
              htmlType="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {t("submit")}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
export default TaskCreationScreen;
