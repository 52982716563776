import { useMemo } from "react";

export default function useFormInitialValues<T>(initialValue: T) {
  const formValues = useMemo(
    () =>
      Object.entries(initialValue || {}).map(([key, value]) => ({
        key,
        value,
      })),
    [initialValue]
  );

  return useMemo(
    () =>
      formValues.reduce(
        (acc, field) => ({ ...acc, [field.key]: field.value }),
        {} as T
      ),
    [formValues]
  );
}
