import { CSSProperties, FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
};
const Box: FC<Props> = ({ children }) => {
  const styles: CSSProperties = {
    direction: "initial",
    color: "rgba(0, 0, 0, 0.88)",
    lineHeight: "1.5714285714285714",
    fontSize: "14px",
    padding: "42px 24px 50px",
    position: "relative",
    display: "inline-block",
    width: "100%",
    margin: "0 0 16px",
    backgroundColor: "#ffffff",
    border: "1px solid rgba(5, 5, 5, 0.06)",
    borderRadius: "6px",
    transition: "all 0.2s",
    minWidth: "400px",
  };
  return <div style={styles}>{children}</div>;
};
export default Box;
