import { useQuery } from "@apollo/client";
import { GET_TASK_DETAIL } from "../apolloClient/queryConstraint";
import { Task } from "../types/task";

const useTaskQuery = (id: number) => {
  const { data, loading } = useQuery(GET_TASK_DETAIL, {
    variables: {
      id,
    },
  });

  return {
    loading,
    data: data?.taskDetail as Task | undefined,
  };
};

export default useTaskQuery;
