import { FC } from "react";
import { Input, Select, Space } from "antd";
import { Project } from "../../types/project";
import { TaskSearchCondition } from "../../types/task";
import useDebouncedInput from "../../hooks/useDebouncedInput";
import { useTranslation } from "react-i18next";

type Option = {
  label: string;
  value: string;
};
type Props = {
  project?: Project;
  onChangeCondition: (condition: TaskSearchCondition) => void;
  condition: TaskSearchCondition;
  nonFinishedOptions?: Option[];
};
const TaskSearchInput: FC<Props> = ({
  project,
  onChangeCondition,
  condition,
  nonFinishedOptions,
}) => {
  const { t } = useTranslation();

  const { handleInputChange } = useDebouncedInput({
    initialValue: condition.taskName,
    onChange: (value) => onChangeCondition({ ...condition, taskName: value }),
  });

  const options = project?.blueprint.nodes.map(({ label, name }) => ({
    label,
    value: name,
  }));

  return (
    <div style={{ margin: "20px 0" }}>
      <Space>
        <Input
          placeholder={t("enter_task_name")}
          onChange={({ target: { value } }) => handleInputChange(value)}
          allowClear
        />
        <Select
          placeholder={t("select_status")}
          options={!!nonFinishedOptions ? nonFinishedOptions : options}
          value={condition.status}
          allowClear
          onChange={(value) => {
            onChangeCondition({ ...condition, status: value });
          }}
        />
      </Space>
    </div>
  );
};
export default TaskSearchInput;
