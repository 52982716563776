import { FC, ReactNode } from "react";
import { OrganizationResource } from "@clerk/types/dist/organization";
import { Result } from "antd";

type Props = {
  organization?: OrganizationResource | null;
  children: ReactNode;
  fallback?: ReactNode;
};
const OrganizationAuthorization: FC<Props> = ({
  organization,
  fallback = (
    <Result title="Taskpassを使い始めるには、まず組織を作成してください。" />
  ),
  children,
}) => {
  return <>{!!organization ? children : fallback}</>;
};
export default OrganizationAuthorization;
