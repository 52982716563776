import { FC } from "react";
import { Table } from "antd";
import { User } from "../../../types/user";
import { History } from "../../../types/history";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import AvatarLabelGroup from "../../AvatarLabelGroup/AvatarLabelGroup";
import { Project } from "../../../types/project";
import StatusTag from "../../StatusTag/StatusTag";
import dayjs from "dayjs";

type Props = {
  project: Project;
  histories?: History[];
};

type TableData = {
  key: string;
  user?: User;
  action: History["type"];
  from?: string | null;
  to?: string | null;
  time?: string;
};

const HistoryTable: FC<Props> = ({ histories, project }) => {
  const { t } = useTranslation();
  const {
    blueprint: { nodes },
  } = project;
  const columns: ColumnsType<TableData> = [
    {
      title: t("user"),
      dataIndex: "user",
      key: "user",
      render: (_, record) => {
        return (
          <AvatarLabelGroup
            src={record.user?.avatar}
            text={record.user?.name}
          />
        );
      },
    },
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
    },
    {
      title: t("from"),
      dataIndex: "from",
      key: "from",
      render: (_, record) => (
        <StatusTag taskStatus={record.from} step={nodes} />
      ),
    },
    {
      title: t("to"),
      dataIndex: "to",
      key: "to",
      render: (_, record) => <StatusTag taskStatus={record.to} step={nodes} />,
    },
    {
      title: t("time"),
      dataIndex: "time",
      key: "time",
    },
  ];

  const tableData: TableData[] | undefined = histories?.map(
    ({ createdAt, user, metadata, type }, index) => {
      return {
        key: `${type}_${index}`,
        action: t(type),
        time: dayjs(createdAt).format("YYYY-MM-DD[ ]HH:mm"),
        user,
        from: metadata?.beforeStatus as string,
        to: metadata?.afterStatus as string,
      };
    }
  );

  return <Table columns={columns} dataSource={tableData} />;
};
export default HistoryTable;
