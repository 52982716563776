import { FC } from "react";
import { Button, ColorPicker, Form, Input, Space, Typography } from "antd";
import { Step } from "../../types/project";
import { useTranslation } from "react-i18next";
import useFormInitialValues from "../../hooks/useFormInitialValues";
import omit from "lodash.omit";

type Props = {
  handleSubmit: (values: Record<string, string>) => void;
  handleCancel: () => void;
  step: Step;
};

const { Text } = Typography;
const EditableForm: FC<Props> = ({ step, handleSubmit, handleCancel }) => {
  const { t } = useTranslation();
  const initialFormValues = useFormInitialValues(step);
  return (
    <Form
      layout="vertical"
      initialValues={omit(initialFormValues, ["buttons"])}
      onFinish={handleSubmit}
      style={{ width: "100%" }}
    >
      {Object.entries(omit(step, ["buttons"])).map(([key]) => (
        <Form.Item
          key={key}
          name={key}
          label={<Text style={{ textTransform: "capitalize" }}>{key}</Text>}
        >
          {key === "color" ? <ColorPicker /> : <Input />}
        </Form.Item>
      ))}

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            {t("save")}
          </Button>
          <Button onClick={handleCancel}>{t("cancel")}</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default EditableForm;
