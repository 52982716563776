import React, { FC, ReactNode } from "react";
import {
  DashboardOutlined,
  FileOutlined,
  FolderOpenOutlined,
  LaptopOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu, Space, theme, Typography } from "antd";
import {
  OrganizationSwitcher,
  useOrganization,
  UserButton,
  useUser,
} from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import { getUserRole } from "../../utils/utilize";
import RoleBasedVisibility from "../RoleBasedVisibility/RoleBasedVisibility";
import { useTranslation } from "react-i18next";
import LocaleSwitcher from "../LocaleSwitcher/LocaleSwitcher";
import useProjectsQuery from "../../hooks/useProjectsQuery";

const { Title } = Typography;
const { Header, Content, Sider } = Layout;

type Props = {
  children: ReactNode;
  handleChangeLocale: (locale: string) => void;
};
const Index: FC<Props> = ({ children, handleChangeLocale }) => {
  const { t } = useTranslation();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { organization } = useOrganization();
  const { user } = useUser();
  const userRole = getUserRole({ user, organization });

  const { projects } = useProjectsQuery(organization?.slug);

  const siderButtons: MenuProps["items"] = [
    {
      key: `sub${0}`,
      icon: React.createElement(FolderOpenOutlined),
      label: t("tasks"),
      children: projects?.map((project, index) => ({
        key: `tasks-management${index}`,
        icon: React.createElement(FileOutlined),
        label: (
          <Link
            to={`/organization/${organization?.id}/projects/${project.slug}/tasks-management`}
          >
            {project.name}
          </Link>
        ),
      })),
    },
    {
      key: `sub${1}`,
      icon: React.createElement(LaptopOutlined),
      label: (
        <Link to={`/organization/${organization?.id}/projects`}>
          {t("project")}
        </Link>
      ),
    },
    {
      key: `sub${2}`,
      icon: React.createElement(DashboardOutlined),
      label: t("analytics"),
      children: projects?.map((project, index) => ({
        key: `analytics${index}`,
        icon: React.createElement(FileOutlined),
        label: (
          <Link
            to={`/organization/${organization?.id}/projects/${project.slug}/analytics`}
          >
            {project.name}
          </Link>
        ),
      })),
    },
  ];
  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "white",
          position: "sticky",
          top: 0,
          zIndex: 1,
          borderBottom: "1px solid #ccc",
        }}
      >
        <div className="demo-logo">
          <Link to={"/"}>
            <Title
              level={3}
              style={{
                margin: 0,
              }}
            >
              Taskpass
            </Title>
          </Link>
        </div>
        <Space wrap>
          <LocaleSwitcher handleChangeLocale={handleChangeLocale} />
          <div
            style={{
              maxHeight: 40,
            }}
          >
            <OrganizationSwitcher hidePersonal={true} />
          </div>
          <UserButton />
        </Space>
      </Header>
      <RoleBasedVisibility
        viewable={["admin"]}
        viewer={userRole}
        fallback={
          <Content
            style={{
              background: colorBgContainer,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        }
      >
        <Layout style={{ padding: "24px 0", background: colorBgContainer }}>
          {!!organization && (
            <Sider
              width={200}
              style={{
                background: "white",
                position: "fixed",
                height: "100vh",
              }}
            >
              <Menu
                mode="inline"
                style={{ height: "100%", borderRight: 0 }}
                items={siderButtons}
              />
            </Sider>
          )}
          <Content
            style={{
              minHeight: 280,
              marginLeft: !!organization ? 250 : 0,
              marginRight: !!organization ? 20 : 0,
            }}
          >
            {children}
          </Content>
        </Layout>
      </RoleBasedVisibility>
    </Layout>
  );
};
export default Index;
