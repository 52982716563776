import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en/translation.json";
import jaTranslation from "./locales/ja/translation.json";
import viTranslation from "./locales/vi/translation.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    ja: {
      translation: jaTranslation,
    },
    vi: {
      translation: viTranslation,
    },
  },
  fallbackLng: "ja",
  debug: true,
  interpolation: {
    prefix: "{{",
    suffix: "}}",
  },
});

export default i18n;
