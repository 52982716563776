import { FC, useState } from "react";
import { Project } from "../../types/project";
import { Button, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { OrganizationResource } from "@clerk/types/dist/organization";
import { useTranslation } from "react-i18next";
import NextTaskModal from "../NextTaskModal/NextTaskModal";
import StatusTransitionButton from "../StatusTransitionButton/StatusTransitionButton";

type Props = {
  project: Project;
  onStateChange: (newState: string) => Promise<boolean>;
  currentStatus?: string;
  isButtonDisabled?: boolean;
  organization?: OrganizationResource | null;
};
const StatusNavigationButtons: FC<Props> = ({
  project,
  onStateChange,
  currentStatus,
  isButtonDisabled = false,
  organization,
}) => {
  const {
    blueprint: { graph },
  } = project;

  const { t } = useTranslation();
  const nav = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const acceptedGraph = graph.filter(({ from }) => from === currentStatus);

  const handleStatusChange = async (to: string) => {
    const isSuccess = await onStateChange(to);
    if (isSuccess && project.setting.showNotFinishedTask) {
      nav(`/organization/${organization?.id}/projects/${project.slug}`);
    } else if (isSuccess) {
      setIsOpen(true);
    }
  };

  return (
    <Space
      style={{
        width: "100vw",
        position: "fixed",
        bottom: 0,
        justifyContent: "end",
        padding: "24px 48px",
        borderTop: "1px solid #ccc",
        backgroundColor: "white",
        zIndex: 10,
      }}
      align={"baseline"}
    >
      {!!acceptedGraph.length ? (
        acceptedGraph.map(({ to, label, color }, index) => {
          return (
            <StatusTransitionButton
              key={index}
              label={label}
              colorBgContainer={color}
              onClick={() => handleStatusChange(to)}
              disabled={isButtonDisabled}
            />
          );
        })
      ) : (
        <Button onClick={() => setIsOpen(true)}>{t("next_task")}</Button>
      )}
      <NextTaskModal
        isOpen={isOpen}
        handleCancel={() => setIsOpen(false)}
        project={project}
        organization={organization}
      />
    </Space>
  );
};
export default StatusNavigationButtons;
