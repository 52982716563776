import { FC } from "react";
import { FormListFieldData, FormListOperation } from "antd/es/form/FormList";
import { Button, Form, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "../TaskCreationScreen/index.module.css";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";

type Props = {
  fields: FormListFieldData[];
  operation: FormListOperation;
  isSubmitting?: boolean;
};
const FormFields: FC<Props> = ({
  operation: { add, remove },
  fields,
  isSubmitting = false,
}) => {
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 0 },
    },
  };

  useHotkeys("Enter", () => {
    add();
  });
  const { t } = useTranslation();
  return (
    <>
      {fields.map(({ key, name, ...restField }) => {
        return (
          <Form.Item key={key} style={{ position: "relative" }}>
            <Form.Item
              {...formItemLayoutWithOutLabel}
              {...restField}
              label={t("task_name")}
              name={[name, "task_name"]}
              rules={[{ required: true, message: "Missing task name" }]}
            >
              <Input
                autoFocus
                onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
                onKeyDown={(e) => e.key === "Enter" && add()}
                placeholder="Input task name"
                style={{ width: "90%" }}
              />
            </Form.Item>
            <MinusCircleOutlined
              className={styles.dynamicDeleteButton}
              onClick={() => {
                remove(name);
              }}
            />
          </Form.Item>
        );
      })}
      <Form.Item
        wrapperCol={{
          span: 20,
          offset: 3,
        }}
      >
        <Button
          type="dashed"
          style={{
            width: "90%",
          }}
          onClick={() => {
            add();
          }}
          block
          disabled={isSubmitting}
          icon={<PlusOutlined />}
        >
          {t("task_creation")}
        </Button>
      </Form.Item>
    </>
  );
};
export default FormFields;
