import { gql } from "@apollo/client";

export const UPDATE_PROJECT_SETTING = gql`
  mutation updateProjectSetting($setting: JSONObject!, $slug: String!) {
    updateProjectSetting(setting: $setting, projectSlug: $slug) {
      id
      slug
      setting
    }
  }
`;

export const UPDATE_PROJECT_BLUEPRINT = gql`
  mutation updateProjectBlueprint($blueprint: JSONObject!, $slug: String!) {
    updateProjectBlueprint(blueprint: $blueprint, projectSlug: $slug) {
      blueprint
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject(
    $orgSlug: String!
    $projectSlug: String!
    $projectName: String!
    $description: String
    $blueprint: JSONObject
    $setting: JSONObject
  ) {
    createProject(
      organizationSlug: $orgSlug
      projectSlug: $projectSlug
      projectName: $projectName
      description: $description
      blueprint: $blueprint
      setting: $setting
    ) {
      slug
      name
    }
  }
`;

export const CREATE_TASKS = gql`
  mutation createTasks(
    $taskNames: [String!]!
    $userUID: String!
    $projectSlug: String!
  ) {
    createTasks(
      taskNames: $taskNames
      userUID: $userUID
      projectSlug: $projectSlug
    )
  }
`;

export const UPDATE_TASK_STATUS = gql`
  mutation updateTaskStatus(
    $status: String!
    $userUID: String!
    $taskId: Float!
  ) {
    updateTaskStatus(status: $status, userUID: $userUID, taskId: $taskId) {
      status
    }
  }
`;

export const CREATE_TASKS_FROM_CSV = gql`
  mutation createTasksFromCSV(
    $url: String!
    $userUID: String!
    $slug: String!
  ) {
    createTasksFromCSV(url: $url, userUID: $userUID, projectSlug: $slug)
  }
`;

export const ARCHIVE_PROJECT = gql`
  mutation archiveProject($slug: String!) {
    archiveProject(projectSlug: $slug) {
      name
    }
  }
`;

export const UPDATE_TASK_NAME = gql`
  mutation updateTaskName($newTaskName: String!, $taskName: Float!) {
    updateTaskName(newTaskName: $newTaskName, taskId: $taskName) {
      name
    }
  }
`;

export const ARCHIVE_TASKS = gql`
  mutation archiveTask($userUID: String!, $taskNames: [Float!]!) {
    archiveTask(userUID: $userUID, taskIds: $taskNames) {
      name
    }
  }
`;

export const UPDATE_TASK_STATUS_BY_STEP = gql`
  mutation updateTaskStatusByStep(
    $status: String!
    $userUid: String!
    $taskId: Float!
  ) {
    updateTaskStatusByStep(
      status: $status
      userUID: $userUid
      taskId: $taskId
    ) {
      status
    }
  }
`;
