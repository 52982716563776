import { FC, useState } from "react";
import TaskTable from "../TaskTable/TaskTable";
import { MembershipRole } from "@clerk/types/dist/organizationMembership";
import { Col, Row, Typography } from "antd";
import { Project } from "../../types/project";
import { useQuery } from "@apollo/client";
import { SEARCH_TASKS } from "../../apolloClient/queryConstraint";
import { Task, TaskSearchCondition } from "../../types/task";
import { useParams } from "react-router-dom";
import { OrganizationResource } from "@clerk/types/dist/organization";
import { useTranslation } from "react-i18next";
import TaskSearchInput from "../TaskSearchInput/TaskSearchInput";
import ShowNotFinishedTaskScreen from "./ShowNotFinishedTaskScreen";

type Props = {
  role?: MembershipRole;
  project: Project;
  organization?: OrganizationResource | null;
};
const { Title } = Typography;
const WorkerProjectScreen: FC<Props> = ({ role, project, organization }) => {
  const { t } = useTranslation();
  const { projectId, projectSlug } = useParams();

  const [condition, setCondition] = useState<TaskSearchCondition>({
    taskName: "",
  });

  const { data: taskQuery, loading: isFetchingTasks } = useQuery(SEARCH_TASKS, {
    variables: {
      taskName: condition.taskName,
      taskStatus: condition.status,
      slug: `${projectId}/${projectSlug}`,
    },
  });

  const tasks: Task[] | undefined = taskQuery?.searchTask;
  const handleChangeCondition = (condition: TaskSearchCondition) => {
    setCondition(condition);
  };

  const options = project?.blueprint.nodes
    .filter(({ name }) => name !== "complete")
    .map(({ label, name }) => ({ label, value: name }));
  return (
    <>
      {project.setting.showNotFinishedTask ? (
        <Row justify="center">
          <Col span={20}>
            <Title level={2}>{t("please_choose_task_to_start")}</Title>
            <TaskSearchInput
              onChangeCondition={handleChangeCondition}
              condition={condition}
              project={project}
              nonFinishedOptions={options}
            />
            <TaskTable
              tasks={tasks}
              role={role}
              project={project}
              loading={isFetchingTasks}
            />
          </Col>
        </Row>
      ) : (
        <ShowNotFinishedTaskScreen
          project={project}
          organization={organization}
        />
      )}
    </>
  );
};
export default WorkerProjectScreen;
