import { FC } from "react";
import { Connection, Step } from "../../types/project";
import { Button, Form, Select, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import useFormInitialValues from "../../hooks/useFormInitialValues";

type Props = {
  nodes: Step[];
  currentGraph: Connection;
  handleSubmit: (values: Record<string, string>, index: number) => void;
  handleCancel: () => void;
  currentIndex: number;
};

const { Text } = Typography;
const GraphEditingForm: FC<Props> = ({
  nodes,
  currentGraph,
  handleSubmit,
  handleCancel,
  currentIndex,
}) => {
  const { t } = useTranslation();

  const initialValue = useFormInitialValues(currentGraph);
  const graphLabelOptions = [
    {
      label: t("次へ"),
      value: "次へ",
    },
    {
      label: t("戻る"),
      value: "戻る",
    },
  ];

  const toOptions = nodes.map(({ name }) => ({
    label: name,
    value: name,
    disabled: name === currentGraph.from,
  }));
  return (
    <Form
      layout="vertical"
      initialValues={initialValue}
      onFinish={(values) => {
        handleSubmit(values, currentIndex);
        handleCancel();
      }}
    >
      {Object.entries(currentGraph)
        .filter(([key]) => key !== "from" && key !== "color")
        .map(([key]) => (
          <Form.Item
            key={key}
            name={key}
            label={<Text style={{ textTransform: "capitalize" }}>{key}</Text>}
          >
            <Select
              options={key === "to" ? toOptions : graphLabelOptions}
              placeholder={
                key === "to" ? t("select_to") : t("select_button_label")
              }
            />
          </Form.Item>
        ))}

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            {t("save")}
          </Button>
          <Button onClick={handleCancel} htmlType="button">
            {t("cancel")}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default GraphEditingForm;
