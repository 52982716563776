import { FC, useState } from "react";
import { Button, Col, Input, message, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_TASK } from "../../apolloClient/queryConstraint";
import { useNavigate } from "react-router-dom";
import { Project } from "../../types/project";
import useDebouncedInput from "../../hooks/useDebouncedInput";
import { OrganizationResource } from "@clerk/types/dist/organization";
import Box from "../Box/Box";

type Props = {
  organization?: OrganizationResource | null;
  project?: Project;
};

const { Title } = Typography;
const ShowNotFinishedTaskScreen: FC<Props> = ({ project, organization }) => {
  const [searchValue, setSearchValue] = useState<string>();

  const { handleInputChange } = useDebouncedInput({
    initialValue: searchValue,
    onChange: (value) => setSearchValue(value),
  });
  const nav = useNavigate();
  const [getTask, { loading, error }] = useLazyQuery(SEARCH_TASK);

  const { t } = useTranslation();

  const handleSearch = () => {
    getTask({
      variables: {
        slug: project?.slug,
        taskName: searchValue,
      },
    })
      .then(({ data }) => {
        nav(
          `/organization/${organization?.id}/projects/${project?.slug}/tasks/${data.searchOneTask?.id}`
        );
      })
      .catch(async () => {
        if (error) {
          message.error({
            content: <>{t(error.graphQLErrors[0].extensions.code as string)}</>,
          });
        }
      });
  };
  return (
    <Row justify="center">
      <Col span={18}>
        <Title level={2}>{t("enter_task_name_to_continue")}</Title>
        <Box>
          <Input
            style={{
              width: "92%",
              marginRight: "2%",
            }}
            placeholder={t("enter_task_name")}
            onChange={({ target: { value } }) => handleInputChange(value)}
            autoFocus
          />
          <Button loading={loading} disabled={loading} onClick={handleSearch}>
            {t("search")}
          </Button>
        </Box>
      </Col>
    </Row>
  );
};
export default ShowNotFinishedTaskScreen;
