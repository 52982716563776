import { FC } from "react";
import { Step } from "../../types/project";
import { Tag } from "antd";

type Props = {
  step?: Step[];
  taskStatus?: string | null;
};
const StatusTag: FC<Props> = ({ step, taskStatus }) => {
  const statusEntities = step?.find(({ name }) => name === taskStatus);
  return <Tag color={statusEntities?.color}>{statusEntities?.label}</Tag>;
};
export default StatusTag;
