import { useQuery } from "@apollo/client";
import { USER_ANALYTICS } from "../apolloClient/queryConstraint";
import dayjs from "dayjs";
import type { RangeValue } from "rc-picker/lib/interface";
import { getDateRange } from "../utils/utilize";
import { UserAnalyticsResult } from "../types/userAnalytics";

const useUserAnalytics = (
  projectSlug: string,
  range: RangeValue<dayjs.Dayjs>
) => {
  const { data, loading } = useQuery(USER_ANALYTICS, {
    variables: {
      projectSlug,
      ...getDateRange(range),
    },
  });

  return {
    loading,
    analyticsResult: data?.userAnalytics as UserAnalyticsResult | undefined,
  };
};

export default useUserAnalytics;
