import { Connection, Project, Step } from "../types/project";
import { OrganizationResource } from "@clerk/types/dist/organization";
import { UserResource } from "@clerk/types/dist/user";
import { UserData } from "../hooks/useUsers";
import { ENCODE_TYPE } from "../enum/ENCODE_TYPE";
import { convertArrayToCSV } from "convert-array-to-csv";
import * as iconv from "iconv-lite";
import type { RangeValue } from "rc-picker/lib/interface";
import { Dayjs } from "dayjs";
import dayjs from "../day";
import { History } from "../types/history";
import { CsvResult } from "../types/csvResult";

type UserRoleSearchParam = {
  user?: UserResource | null;
  organization?: OrganizationResource | null;
};

type CSVParams = {
  obj: CsvResult;
  encodeType?: ENCODE_TYPE;
};

export type DateRange = {
  startDate?: string;
  endDate?: string;
};

export type ExportOptions = {
  range?: DateRange;
  encode: ENCODE_TYPE;
  type: History["type"];
};
export const getUserRole = ({ user, organization }: UserRoleSearchParam) => {
  return user?.organizationMemberships.find(
    ({ organization: organizationMembership }) =>
      organizationMembership === organization
  )?.role;
};

export const getUserDataById = (userData: UserData[] = [], uid: string) => {
  return userData.find(({ publicUserData: { userId } }) => userId === uid);
};

export const getCurrentStatus = (statusName?: string, project?: Project) =>
  project?.blueprint.nodes.find(({ name }) => name === statusName);

export const convertSubmittedTasksNameToArray = (
  submittedValues: Record<string, string | Record<string, string>[]>
) =>
  Object.values(submittedValues).reduce((accumulator, value) => {
    if (Array.isArray(value)) {
      const subTaskNames = value.map(({ task_name }) => task_name);
      return accumulator.concat(subTaskNames);
    } else {
      return accumulator.concat(value);
    }
  }, [] as string[]);

export const downloadCSV = ({
  obj,
  encodeType = ENCODE_TYPE.UTF8,
}: CSVParams) => {
  const csv = convertArrayToCSV(obj.csvRows, {
    header: obj.csvHead,
    separator: ",",
  });
  const data = iconv.encode(
    csv,
    encodeType === ENCODE_TYPE.SHIFT_JIS
      ? ENCODE_TYPE.SHIFT_JIS
      : ENCODE_TYPE.UTF8
  );
  const download = (blob: any, type: any) => {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `tasks.${type}`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  };
  const blob = new Blob([data], { type: "text/csv" });
  return download(blob, "csv");
};

export const getDateRange = (initRange?: RangeValue<Dayjs>) => {
  if (!!initRange) {
    return {
      startDate: dayjs(initRange[0]).utc().format("YYYY-MM-DD"),
      endDate: dayjs(initRange[1]).utc().format("YYYY-MM-DD"),
    };
  }
};

export const getStatusEntities = (nodes: Step[] = [], statusName: string) =>
  nodes.find(({ name }) => name === statusName);

export const getNewStatus = (
  previous: string,
  current: string,
  { blueprint: { graph, nodes } }: Project
) => {
  const beforeNodeIndex = nodes.findIndex(({ name }) => previous === name);
  const beforeGraphIndex = graph.findIndex(
    ({ from }) => from === nodes.find(({ name }) => previous === name)?.name
  );
  const newBeforeGraph = {
    ...graph.find(
      ({ from }) => from === nodes.find(({ name }) => previous === name)?.name
    ),
    to: current,
  } as Connection;

  return {
    newBeforeGraph,
    beforeNodeIndex,
    beforeGraphIndex,
  };
};

export const updatedButtonsByStatus = (
  currentNode: Step,
  newButton: Record<string, string>,
  index: number
) => {
  const updatedButtons = currentNode.buttons?.map((button, currentIndex) =>
    currentIndex === index ? newButton : button
  );

  return {
    ...currentNode,
    buttons: updatedButtons,
  };
};

export const convertHexToRGB = (hex: string, alpha: string = "1") => {
  hex = hex.replace("#", "");

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgb(${r}, ${g}, ${b}, ${alpha})` as const;
};
