import { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  Col,
  List,
  message,
  Row,
  Space,
  Switch,
  Typography,
} from "antd";
import { OrganizationMembershipResource } from "@clerk/types/dist/organizationMembership";
import { OrganizationResource } from "@clerk/types/dist/organization";
import { Connection, Project, Setting, Step } from "../../types/project";
import { useMutation } from "@apollo/client";
import {
  UPDATE_PROJECT_BLUEPRINT,
  UPDATE_PROJECT_SETTING,
} from "../../apolloClient/mutation";
import { useTranslation } from "react-i18next";
import AddStatusModal from "../AddStatusModal/AddStatusModal";
import { Color } from "antd/es/color-picker";
import { GET_PROJECT_DETAIL } from "../../apolloClient/queryConstraint";
import { getNewStatus } from "../../utils/utilize";
import { downloadJSONFile } from "../../utils/downloadJSONFile";
import useExportProjectBlueprint from "../../hooks/useExportProjectBlueprint";

const { Title, Text, Paragraph } = Typography;
type Props = {
  organization?: OrganizationResource | null;
  membershipResource: OrganizationMembershipResource[];
  project: Project;
  loading?: boolean;
};
const ProjectSettings: FC<Props> = ({
  project,
  organization,
  membershipResource,
  loading = false,
}) => {
  const { projectId, projectSlug } = useParams();
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const [open, setOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [setting, setSetting] = useState<Setting>(project.setting);

  const { blueprint } = useExportProjectBlueprint(
    `${projectId}/${projectSlug}`
  );
  const [updateProjectSetting, { error }] = useMutation(UPDATE_PROJECT_SETTING);
  const [updateProjectBlueprint, { error: blueprintUpdateErr }] = useMutation(
    UPDATE_PROJECT_BLUEPRINT
  );

  const handleUpdateTaskListSetting = (checked: boolean) => {
    setSetting({ ...setting, showNotFinishedTask: checked });
  };
  const handleUpdateTaskDetailSetting = (checked: boolean) => {
    setSetting({ ...setting, showStatusStep: checked });
  };

  const handleSubmit = (values: Record<string, string | Color>) => {
    const newNode: Step = {
      name: values.node_name as string,
      label: values.node_label as string,
      color:
        typeof values.color === "string"
          ? values.color
          : values.color.toHexString(),
    };

    const { newBeforeGraph, beforeNodeIndex, beforeGraphIndex } = getNewStatus(
      values.previous as string,
      values.node_name as string,
      project
    );

    const newGraph: Connection = {
      from: values.node_name as string,
      to: values.to as string,
      color:
        typeof values.color === "string"
          ? values.color
          : values.color.toHexString(),
      label: values.graph_label as string,
    };

    const updatedGraphs = [
      ...project.blueprint.graph.map((oldGraph, index) =>
        index === beforeGraphIndex ? newBeforeGraph : oldGraph
      ),
      newGraph,
    ];
    const updatedSteps = [...project.blueprint.nodes];
    updatedSteps.splice(beforeNodeIndex + 1, 0, newNode);

    updateProjectBlueprint({
      variables: {
        slug: `${projectId}/${projectSlug}`,
        blueprint: {
          ...project.blueprint,
          nodes: updatedSteps,
          graph: updatedGraphs,
        },
      },
      refetchQueries: [GET_PROJECT_DETAIL],
    })
      .then(async () => {
        setIsSubmitting(false);
        await messageApi.success({
          content: "Added new status successfully",
        });
        setOpen(false);
      })
      .catch(async () => {
        if (blueprintUpdateErr)
          await messageApi.error({
            content: (
              <>
                {t(
                  blueprintUpdateErr.graphQLErrors[0].extensions.code as string
                )}
              </>
            ),
          });
        setIsSubmitting(false);
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleUpdateProjectSetting = () => {
    updateProjectSetting({
      variables: {
        setting,
        slug: `${projectId}/${projectSlug}`,
      },
    })
      .then(async () => {
        await messageApi.success({
          content: "Update settings successfully",
        });
        setIsUpdating(false);
      })
      .catch(async () => {
        if (error)
          message.error({
            content: <>{error.graphQLErrors[0].extensions.code}</>,
          });
        setIsUpdating(false);
      });
  };
  return (
    <>
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3}>{t("project_setting")}</Title>
        <Button
          type="primary"
          size="large"
          onClick={() => downloadJSONFile(blueprint, "setting")}
        >
          {t("export")}
        </Button>
      </div>
      <Paragraph>
        {t("project_name")}: {project?.name}
      </Paragraph>
      <Space align="baseline" size={"large"}>
        <Title level={4}>{t("statuses")}</Title>
        <Button onClick={handleOpen}>{t("add_status")}</Button>
        <AddStatusModal
          blueprint={project.blueprint}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          onCancel={handleClose}
          isOpen={open}
        />
      </Space>
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={project.blueprint.nodes}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Link
                to={`/organization/${organization?.id}/projects/${project.slug}/statuses/${item.name}`}
              >
                <Button>{t("settings")}</Button>
              </Link>,
            ]}
          >
            <List.Item.Meta title={item.label} description={item.name} />
          </List.Item>
        )}
      />

      <List
        loading={loading}
        header={<Title level={3}>{t("accounts")}</Title>}
        itemLayout="horizontal"
        dataSource={membershipResource.map(
          ({ publicUserData }) => publicUserData
        )}
        renderItem={({ firstName, lastName, imageUrl, identifier }) => (
          <List.Item>
            <List.Item.Meta
              title={`${firstName} ${lastName}`}
              description={identifier}
              avatar={<Avatar src={imageUrl} />}
            />
          </List.Item>
        )}
      />
      <Title>{t("customize_on_the_worker_screen")}</Title>

      <Row>
        <Col span={24}>
          <Paragraph style={{ display: "block" }}>{t("task_list")}</Paragraph>
          <Space align="center">
            <Text>{t("show_not_finished_tasks")}:</Text>
            <Switch
              loading={isUpdating}
              disabled={isUpdating}
              defaultChecked={project.setting.showNotFinishedTask}
              onChange={handleUpdateTaskListSetting}
            />
          </Space>
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col span={24}>
          <Paragraph style={{ display: "block" }}>{t("task_detail")}</Paragraph>
          <Space align="center">
            <Text>{t("show_status_steps")}:</Text>
            <Switch
              loading={isUpdating}
              disabled={isUpdating}
              defaultChecked={project.setting.showStatusStep}
              onChange={handleUpdateTaskDetailSetting}
            />
          </Space>
        </Col>
      </Row>
      <Button
        style={{ marginTop: 20 }}
        type="primary"
        onClick={handleUpdateProjectSetting}
      >
        {t("save")}
      </Button>
    </>
  );
};
export default ProjectSettings;
