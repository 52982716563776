import { FC } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";
type Props = {
  isModalOpen: boolean;
  isSubmitting: boolean;
  handleSubmit: (value: any) => void;
  handleCancel: () => void;
  handleChangeFile: (file: File) => void;
};
const CreateProjectModal: FC<Props> = ({
  isModalOpen,
  isSubmitting,
  handleCancel,
  handleSubmit,
  handleChangeFile,
}) => {
  const { t } = useTranslation();
  const props: UploadProps = {
    name: "file",
    beforeUpload: (file) => {
      handleChangeFile(file);
      return false;
    },
  };
  return (
    <Modal
      title={
        <Typography.Title level={4}>{t("create_project")}</Typography.Title>
      }
      open={isModalOpen}
      confirmLoading={isSubmitting}
      onOk={handleSubmit}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        layout={"vertical"}
        name="basic"
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Form.Item
          label={t("project_name")}
          name="project_name"
          rules={[
            { required: true, message: "Please input project name!" },
            { min: 3, message: "Required at least 3 characters" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("project_slug")}
          name="project_slug"
          rules={[
            { required: true, message: "Please input project slug!" },
            { min: 3, message: "Required at least 3 characters" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={t("project_blueprint")} name="project_blueprint">
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Space
            align="center"
            size={"middle"}
            style={{ justifyContent: "end" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {t("submit")}
            </Button>
            <Button
              htmlType="button"
              onClick={handleCancel}
              disabled={isSubmitting}
            >
              {t("cancel")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default CreateProjectModal;
