import { FC } from "react";
import ProjectList from "../../ProjectList/ProjectList";
import { Col, Row, Typography } from "antd";
import { UserResource } from "@clerk/types/dist/user";
import { OrganizationResource } from "@clerk/types/dist/organization";
import { Trans, useTranslation } from "react-i18next";
import useProjectsQuery from "../../../hooks/useProjectsQuery";

type Props = {
  user?: UserResource | null;
  organization?: OrganizationResource | null;
};

const { Title } = Typography;
const WorkerHomescreen: FC<Props> = ({ organization, user }) => {
  const { projects, loading } = useProjectsQuery(organization?.slug);

  const { t } = useTranslation();
  return (
    <Row justify="center">
      <Col span={18}>
        <Title>
          <Trans
            i18nKey={"welcome_text"}
            values={{
              user_name: user?.username,
              organization_name: organization?.name,
            }}
          />
        </Title>
        <Title level={3}>{t("please_choose_projects")}</Title>
        <ProjectList
          organization={organization}
          projects={projects}
          loading={loading}
        />
      </Col>
    </Row>
  );
};
export default WorkerHomescreen;
