import { useEffect, useState } from "react";

export const useFileReader = (file: File | undefined) => {
  const [result, setResult] = useState<string | undefined>();

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (typeof event.target?.result === "string")
          setResult(JSON.parse(event.target.result));
      };
      reader.readAsText(file);
    }
  }, [file]);

  return { result };
};
