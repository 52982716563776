import { useQuery } from "@apollo/client";
import { EXPORT_PROJECT_BLUEPRINT } from "../apolloClient/queryConstraint";

const useExportProjectBlueprint = (slug: string) => {
  const { data, loading } = useQuery(EXPORT_PROJECT_BLUEPRINT, {
    variables: {
      slug,
    },
  });

  return {
    loading,
    blueprint: data?.exportProjectBlueprint as Object,
  };
};

export default useExportProjectBlueprint;
