import { FC } from "react";
import { DatePicker, Modal, Select, Typography } from "antd";
import { ExportOptions, getDateRange } from "../../utils/utilize";
import { HISTORY_TYPE } from "../../types/history";
import { ENCODE_TYPE } from "../../enum/ENCODE_TYPE";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  exportOptions: ExportOptions;
  onChangeOptions: (options: ExportOptions) => void;
  onExport: () => void;
};
const { RangePicker } = DatePicker;
const { Paragraph } = Typography;

const HistoryExportModal: FC<Props> = ({
  handleClose,
  isOpen,
  onExport,
  exportOptions,
  onChangeOptions,
}) => {
  const { t } = useTranslation();
  const typeOptions = [
    {
      label: HISTORY_TYPE.CREATE,
      value: HISTORY_TYPE.CREATE,
    },
    {
      label: HISTORY_TYPE.CHANGE_STATUS,
      value: HISTORY_TYPE.CHANGE_STATUS,
    },
  ];

  const encodeOptions = [
    {
      label: ENCODE_TYPE.UTF8,
      value: ENCODE_TYPE.UTF8,
    },
    {
      label: ENCODE_TYPE.SHIFT_JIS,
      value: ENCODE_TYPE.SHIFT_JIS,
    },
  ];

  return (
    <Modal
      open={isOpen}
      title={"Export by history"}
      onCancel={handleClose}
      onOk={onExport}
    >
      <Paragraph>{t("choose_range")}</Paragraph>
      <RangePicker
        onChange={(date) => {
          onChangeOptions({ ...exportOptions, range: getDateRange(date) });
        }}
      />

      <Paragraph>{t("choose_encode_type")}</Paragraph>
      <Select
        style={{ display: "block" }}
        placeholder={t("choose_encode_type")}
        options={encodeOptions}
        value={exportOptions.encode}
        onChange={(value) =>
          onChangeOptions({ ...exportOptions, encode: value })
        }
      />

      <Paragraph>{t("choose_export_type")}</Paragraph>
      <Select
        style={{ display: "block" }}
        placeholder={t("choose_encode_type")}
        options={typeOptions}
        value={exportOptions.type}
        onChange={(value) => onChangeOptions({ ...exportOptions, type: value })}
      />
    </Modal>
  );
};
export default HistoryExportModal;
