import { FC } from "react";
import type { Button as ButtonType } from "../../types/project";
import { Button, Form, Input, Select, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import useFormInitialValues from "../../hooks/useFormInitialValues";
import omit from "lodash.omit";
import { BUTTON_TYPE } from "../../enum/BUTTON_TYPE";

type Props = {
  button: ButtonType;
  handleSubmit: (values: Record<string, string>, index: number) => void;
  handleCancel: () => void;
  currentIndex: number;
};

const { Text } = Typography;

const ButtonEditingForm: FC<Props> = ({
  button,
  handleSubmit,
  handleCancel,
  currentIndex,
}) => {
  const { t } = useTranslation();
  const initialValue = useFormInitialValues(button);
  const options = [
    {
      label: t(BUTTON_TYPE.TEXT),
      value: BUTTON_TYPE.TEXT,
    },
    {
      label: t(BUTTON_TYPE.DEFAULT),
      value: BUTTON_TYPE.DEFAULT,
    },
  ];
  return (
    <Form
      layout="vertical"
      initialValues={initialValue}
      onFinish={(values) => {
        handleSubmit(values, currentIndex);
        handleCancel();
      }}
      style={{ width: "100%" }}
    >
      <Form.Item
        initialValue={initialValue.name}
        rules={[
          {
            required: true,
            message: "Field required",
          },
        ]}
        required
        name="name"
        label={t("button_name")}
      >
        <Select placeholder={t("input_button_name")} options={options} />
      </Form.Item>
      {Object.entries(omit(button, ["name"])).map(([key]) => (
        <Form.Item
          key={key}
          name={key}
          label={<Text style={{ textTransform: "capitalize" }}>{key}</Text>}
        >
          <Input />
        </Form.Item>
      ))}

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            {t("save")}
          </Button>
          <Button onClick={handleCancel}>{t("cancel")}</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default ButtonEditingForm;
