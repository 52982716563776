import { gql } from "@apollo/client";
export const GET_PROJECTS = gql`
  query myQuery($organizationSlug: String!) {
    organizationDetail(organizationSlug: $organizationSlug) {
      id
      slug
      name
      _count {
        projects
      }
      projects {
        id
        blueprint
        slug
        name
        setting
        slug
        tasks {
          name
        }
      }
    }
  }
`;

export const GET_PROJECT_DETAIL = gql`
  query projectDetail($slug: String!) {
    projectDetail(projectSlug: $slug) {
      id
      blueprint
      slug
      name
      setting
      organizationSlug
    }
  }
`;

export const GET_TASK_DETAIL = gql`
  query TaskDetail($id: Float!) {
    taskDetail(taskId: $id) {
      id
      name
      status
      createdAt
      updatedAt
      finishedAt
      projectSlug
      user {
        uid
      }
      history {
        type
        createdAt
        createdBy
        user {
          name
          avatar
        }
        metadata
      }
    }
  }
`;

export const SEARCH_TASKS = gql`
  query searchTask($taskName: String!, $taskStatus: String, $slug: String!) {
    searchTask(
      taskName: $taskName
      taskStatus: $taskStatus
      projectSlug: $slug
    ) {
      id
      name
      status
      createdAt
      createdBy
      user {
        uid
        name
        avatar
        email
      }
    }
  }
`;

export const TASK_ANALYTICS = gql`
  query taskAnalytics($projectSlug: String!) {
    taskAnalytics(projectSlug: $projectSlug) {
      status
      count
    }
  }
`;

export const USER_ANALYTICS = gql`
  query userAnalytics(
    $projectSlug: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    userAnalytics(
      projectSlug: $projectSlug
      startDate: $startDate
      endDate: $endDate
    ) {
      userAnalytics {
        uid
        name
        avatar
        analytics {
          status
          count
        }
      }
      total {
        status
        count
      }
    }
  }
`;

export const EXPORT_CSV_BY_HISTORY = gql`
  query exportCSV(
    $startDate: DateTime!
    $endDate: DateTime!
    $type: HistoryType!
    $slug: String!
  ) {
    exportCSVByHistoryType(
      startDate: $startDate
      endDate: $endDate
      historyType: $type
      projectSlug: $slug
    ) {
      csvHead
      csvRows
    }
  }
`;

export const EXPORT_PROJECT_BLUEPRINT = gql`
  query exportProjectBlueprint($slug: String!) {
    exportProjectBlueprint(projectSlug: $slug)
  }
`;

export const SEARCH_TASK = gql`
  query searchTask($slug: String!, $taskName: String!) {
    searchOneTask(projectSlug: $slug, taskName: $taskName) {
      id
      name
      status
      createdAt
      createdBy
      user {
        uid
        name
        avatar
        email
      }
    }
  }
`;
