import { FC } from "react";
import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useParams } from "react-router-dom";
import { Project } from "../../../types/project";
import { useTranslation } from "react-i18next";
import AvatarLabelGroup from "../../AvatarLabelGroup/AvatarLabelGroup";
import StatusTag from "../../StatusTag/StatusTag";
import type { RangeValue } from "rc-picker/lib/interface";
import dayjs from "dayjs";
import useUserAnalytics from "../../../hooks/useUserAnalytics";

type Props = {
    project?: Project;
    range: RangeValue<dayjs.Dayjs>;
};

type TableData = Record<string, any>;

const { Title, Paragraph } = Typography;
const StatusCountByUserTable: FC<Props> = ({ project, range }) => {
    const { projectId, projectSlug } = useParams();
    const { t } = useTranslation();
    const { analyticsResult, loading } = useUserAnalytics(
        `${projectId}/${projectSlug}`,
        range
    );

    const restColumns: ColumnsType<TableData> =
        analyticsResult?.total.map(({ status }) => ({
            title: <StatusTag step={project?.blueprint.nodes} taskStatus={status} />,
            key: status,
            dataIndex: status,
            align: "center",
            render: (_, record) => {
                return <Paragraph>{record[status]}</Paragraph>;
            },
        })) || [];

    const columns: ColumnsType<TableData> = [
        {
            title: t("user"),
            key: "users",
            dataIndex: "users",
            render: (_, record) => (
                <AvatarLabelGroup text={record.users.name} src={record.users.avatar} />
            ),
        },
        ...restColumns,
    ];

    const data = analyticsResult?.userAnalytics.map(
        ({ analytics, uid, name, avatar }) => ({
            key: uid,
            users: {
                name,
                avatar,
            },
            ...analytics.reduce((initialValue, { status, count }) => {
                console.log(status, count);
                return {
                    ...initialValue,
                    [status]: count,
                };
            }, {}),
        })
    );

    return (
        <Table
            summary={() => (
                <Table.Summary.Row
                    style={{
                        background: "#f9f9f9",
                    }}
                >
                    <Table.Summary.Cell index={0}>
                        <Title
                            level={4}
                            style={{
                                margin: 0,
                            }}
                        >
                            {t("total")}
                        </Title>
                    </Table.Summary.Cell>
                    {analyticsResult?.total.map(({ count }, index) => {
                        return (
                            <Table.Summary.Cell key={index} align="center" index={index + 1}>
                                {count}
                            </Table.Summary.Cell>
                        );
                    })}
                </Table.Summary.Row>
            )}
            columns={columns}
            loading={loading}
            dataSource={data}
            bordered
        />
    );
};
export default StatusCountByUserTable;