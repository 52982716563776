import { FC } from "react";
import OrganizationAuthorization from "../OrganizationAuthorization/OrganizationAuthorization";
import TaskDetailScreen from "./TaskDetailScreen";
import RoleBasedVisibility from "../RoleBasedVisibility/RoleBasedVisibility";
import { useOrganization, useUser } from "@clerk/clerk-react";
import { getUserRole } from "../../utils/utilize";
import { useParams } from "react-router-dom";
import WorkerTaskDetailScreen from "./WorkerTaskDetailScreen";
import { Spin } from "antd";
import useTaskQuery from "../../hooks/useTaskQuery";
import useProjectQuery from "../../hooks/useProjectQuery";

type Props = {};
const Container: FC<Props> = () => {
  const { user } = useUser();
  const { organization } = useOrganization();
  const { taskId, projectId, projectSlug } = useParams();
  const role = getUserRole({ user, organization });

  const { data: currentTask, loading: isFetchingTask } = useTaskQuery(
    parseFloat(taskId as string)
  );

  const { project: currentProject, loading: isFetchingProject } =
    useProjectQuery(projectId, projectSlug);

  return (
    <OrganizationAuthorization organization={organization}>
      <Spin spinning={isFetchingProject || isFetchingTask}>
        {!!currentProject && !!currentTask && (
          <RoleBasedVisibility
            viewable={["admin"]}
            viewer={role}
            fallback={
              <WorkerTaskDetailScreen
                organization={organization}
                currentTask={currentTask}
                currentProject={currentProject}
              />
            }
          >
            <TaskDetailScreen
              history={currentTask?.history}
              currentTask={currentTask}
              currentProject={currentProject}
            />
          </RoleBasedVisibility>
        )}
      </Spin>
    </OrganizationAuthorization>
  );
};
export default Container;
