import { DateRange } from "../utils/utilize";
import { HISTORY_TYPE } from "../types/history";
import { useQuery } from "@apollo/client";
import { EXPORT_CSV_BY_HISTORY } from "../apolloClient/queryConstraint";
import { CsvResult } from "../types/csvResult";

const useExportCSVQuery = (
  range: DateRange,
  type: HISTORY_TYPE,
  slug: string
) => {
  const { data, loading, error } = useQuery(EXPORT_CSV_BY_HISTORY, {
    variables: {
      ...range,
      type,
      slug,
    },
  });

  return {
    loading,
    exportData: data?.exportCSVByHistoryType as CsvResult | undefined,
    error,
  };
};

export default useExportCSVQuery;
