import { FC, useEffect, useState } from "react";
import { useOrganization, useUser } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { OrganizationMembershipResource } from "@clerk/types/dist/organizationMembership";
import OrganizationAuthorization from "../OrganizationAuthorization/OrganizationAuthorization";
import RoleBasedVisibility from "../RoleBasedVisibility/RoleBasedVisibility";
import { getUserRole } from "../../utils/utilize";
import ProjectSettings from "../ProjectSettings/ProjectSettings";
import WorkerProjectScreen from "../WorkerProjectScreen/WorkerProjectScreen";
import { useQuery } from "@apollo/client";
import { GET_PROJECT_DETAIL } from "../../apolloClient/queryConstraint";
import { Project } from "../../types/project";
import { Spin } from "antd";

type Props = {};
const ProjectDetailContainer: FC<Props> = () => {
  const { organization } = useOrganization();
  const { user } = useUser();
  const { projectId, projectSlug } = useParams();
  const { data: queryData } = useQuery(GET_PROJECT_DETAIL, {
    variables: {
      slug: `${projectId}/${projectSlug}`,
    },
  });

  const currentProject: Project | undefined = queryData?.projectDetail;
  const [membershipResource, setMembershipResource] = useState<
    OrganizationMembershipResource[]
  >([]);

  const role = getUserRole({ user, organization });

  const [
    isGettingOrganizationMemberResource,
    setIsGettingOrganizationMemberResource,
  ] = useState<boolean>(false);

  useEffect(() => {
    setIsGettingOrganizationMemberResource(true);
    organization
      ?.getMemberships()
      .then((value) => {
        setMembershipResource(value);
        setIsGettingOrganizationMemberResource(false);
      })
      .catch(() => setIsGettingOrganizationMemberResource(false));
  }, []);
  return (
    <OrganizationAuthorization organization={organization}>
      <RoleBasedVisibility
        viewable={["admin"]}
        viewer={role}
        fallback={
          <Spin spinning={!currentProject}>
            {currentProject && (
              <WorkerProjectScreen
                organization={organization}
                project={currentProject}
                role={role}
              />
            )}
          </Spin>
        }
      >
        {currentProject && (
          <ProjectSettings
            membershipResource={membershipResource}
            organization={organization}
            project={currentProject}
            loading={isGettingOrganizationMemberResource}
          />
        )}
      </RoleBasedVisibility>
    </OrganizationAuthorization>
  );
};
export default ProjectDetailContainer;
