import homeEN from "./locales/en/translation.json";
import homeJA from "./locales/ja/translation.json";
import homeVI from "./locales/vi/translation.json";
import { viVN, enUS } from "@clerk/localizations";
import { jaJP } from "./locales/clerk/ja-JP";

const getClerkLanguage = (locale: string) => {
  switch (locale) {
    case "en":
      return enUS;
    case "ja":
      return jaJP;
    case "vi":
      return viVN;
    default:
      return jaJP;
  }
};

export { homeEN, homeJA, homeVI, getClerkLanguage };
