import { Dispatch, FC, Key, SetStateAction } from "react";
import { Button, Table, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";
import { MembershipRole } from "@clerk/types/dist/organizationMembership";
import { Task } from "../../types/task";
import { Project } from "../../types/project";
import { useTranslation } from "react-i18next";
import StatusTag from "../StatusTag/StatusTag";

type Props = {
  role?: MembershipRole;
  tasks?: Task[];
  loading?: boolean;
  project?: Project;
  setSelected?: Dispatch<SetStateAction<Key[]>>;
};

type TableData = {
  key: number;
  taskName: string;
  status: string;
};

const TaskTable: FC<Props> = ({
  role,
  tasks,
  loading = false,
  project,
  setSelected = () => {},
}) => {
  const { t } = useTranslation();
  const { organization } = useOrganization();

  const columns: ColumnsType<TableData> = [
    {
      title: t("task_name"),
      dataIndex: "taskName",
      key: "taskName",
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <StatusTag taskStatus={record.status} step={project?.blueprint.nodes} />
      ),
    },
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Link
            to={`/organization/${organization?.id}/projects/${project?.slug}/tasks/${record.key}`}
          >
            <Button>{role === "admin" ? t("go_to_task") : t("start")}</Button>
          </Link>
        );
      },
    },
  ];

  const rowSelection: TableProps<TableData>["rowSelection"] =
    role === "admin"
      ? {
          type: "checkbox",
          onChange: (selectedRowKeys) => {
            setSelected(selectedRowKeys);
          },
        }
      : undefined;

  const data: TableData[] | undefined = tasks?.map(({ name, status, id }) => ({
    key: id,
    taskName: name,
    status,
  }));
  return (
    <Table
      rowSelection={rowSelection}
      style={{
        marginTop: 20,
      }}
      loading={loading}
      columns={columns}
      dataSource={data}
    />
  );
};
export default TaskTable;
