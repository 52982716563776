import { FC } from "react";
import { useOrganization, useUser } from "@clerk/clerk-react";
import { getUserRole } from "../../utils/utilize";
import RoleBasedVisibility from "../RoleBasedVisibility/RoleBasedVisibility";
import AdminHomescreen from "./AdminHomescreen/AdminHomescreen";
import WorkerHomescreen from "./WorkerHomescreen/WorkerHomescreen";
import OrganizationAuthorization from "../OrganizationAuthorization/OrganizationAuthorization";

type Props = {};

const Home: FC<Props> = () => {
  const { organization } = useOrganization();
  const { user } = useUser();
  const userRole = getUserRole({ user, organization });

  return (
    <OrganizationAuthorization organization={organization}>
      <RoleBasedVisibility
        viewable={["admin"]}
        viewer={userRole}
        fallback={<WorkerHomescreen user={user} organization={organization} />}
      >
        <AdminHomescreen user={user} organization={organization} />
      </RoleBasedVisibility>
    </OrganizationAuthorization>
  );
};
export default Home;
