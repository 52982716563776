import { FC, useState } from "react";
import { message, Typography } from "antd";
import { Task } from "../../types/task";
import { Project } from "../../types/project";
import { History } from "../../types/history";
import { useTranslation } from "react-i18next";
import HistoryTable from "../Table/TaskHistoryTable/Table";
import { useMutation } from "@apollo/client";
import {
  UPDATE_TASK_NAME,
  UPDATE_TASK_STATUS,
} from "../../apolloClient/mutation";
import StatusSelection from "../StatusSelection/StatusSelection";
import { useUser } from "@clerk/clerk-react";
import { GET_TASK_DETAIL } from "../../apolloClient/queryConstraint";

type Props = {
  currentTask?: Task;
  currentProject?: Project;
  history?: History[];
};

const { Title } = Typography;
const TaskDetailScreen: FC<Props> = ({
  currentTask,
  currentProject,
  history,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [updateTaskName, { error: updateTaskNameErr }] =
    useMutation(UPDATE_TASK_NAME);
  const [updateTaskStatus, { error: updateStatusErr }] =
    useMutation(UPDATE_TASK_STATUS);

  const handleChangeStatus = (newStatus: string) => {
    setIsUpdatingStatus(true);
    updateTaskStatus({
      variables: {
        status: newStatus,
        taskId: currentTask?.id,
        userUID: user?.id,
      },
    })
      .then(async () => {
        await messageApi.success({
          content: "Success",
        });
        setIsUpdatingStatus(false);
      })
      .catch(async () => {
        if (updateStatusErr)
          await messageApi.error({
            content: (
              <>
                {t(updateStatusErr.graphQLErrors[0].extensions.code as string)}
              </>
            ),
          });
        setIsUpdatingStatus(false);
      });
  };

  return (
    <>
      {contextHolder}
      <Title
        level={3}
        editable={{
          text: currentTask?.name,
          onChange: (value) => {
            updateTaskName({
              variables: {
                newTaskName: value,
                taskName: currentTask?.id,
              },
              refetchQueries: [GET_TASK_DETAIL],
            })
              .then(async () => {
                await messageApi.success({
                  content: "Success",
                });
              })
              .catch(async () => {
                if (updateTaskNameErr) {
                  await message.error({
                    content: (
                      <>
                        {t(
                          updateTaskNameErr.graphQLErrors[0].extensions
                            .code as string
                        )}
                      </>
                    ),
                  });
                }
              });
          },
        }}
      >
        {t("task_name")}: {currentTask?.name}
      </Title>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Title style={{ margin: "0 10px 0 0" }} level={4}>
          {t("status")}:{" "}
        </Title>
        {currentTask && currentProject && (
          <StatusSelection
            loading={isUpdatingStatus}
            handleChangeStatus={handleChangeStatus}
            currentStatus={currentTask.status}
            currentProject={currentProject}
          />
        )}
      </div>

      <Title>{t("history")}</Title>
      {currentProject && (
        <HistoryTable project={currentProject} histories={history} />
      )}
    </>
  );
};
export default TaskDetailScreen;
