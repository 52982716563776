import React, { FC, useState } from "react";
import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
} from "@clerk/clerk-react";
import Header from "./components/Header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/HomeScreen/Home";
import ProjectScreen from "./components/ProjectScreen";
import { RecoilRoot } from "recoil";
import StatusSetting from "./components/StatusSettingScreen/StatusSettingScreen";
import TaskManagementScreen from "./components/TasksManagement/TaskManagementScreen";
import AnalyticsScreen from "./components/Analytics/AnalyticsScreen";
import TaskCreationScreen from "./components/TaskCreationScreen/TaskCreationScreen";
import BatchCreationScreen from "./components/BatchCreationScreen/BatchCreationScreen";
import ProjectDetailContainer from "./components/ProjectDetail/ProjectDetail";
import Container from "./components/TaskDetailScreen/Container";
import "./firebase";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { getClerkLanguage } from "./locales";
import withApolloClient from "./apolloClient/withApolloClient";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing publishable key");
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

type Props = {
  handleChangeLocale: (locale: string) => void;
};
const ApolloClientProvider: FC<Props> = ({ handleChangeLocale }) => {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <SignedOut>
          <RedirectToSignIn />
        </SignedOut>
        <SignedIn>
          <Header handleChangeLocale={handleChangeLocale}>
            <Routes>
              <Route path="/" element={<Home />} />

              <Route
                path="/organization/:organizationId/projects"
                element={<ProjectScreen />}
              />

              <Route
                path="/organization/:organizationId/projects/:projectId/:projectSlug"
                element={<ProjectDetailContainer />}
              />

              <Route
                path={`/organization/:organizationId/projects/:projectId/:projectSlug/statuses/:statusName`}
                element={<StatusSetting />}
              />

              <Route
                path={`/organization/:organizationId/projects/:projectId/:projectSlug/tasks-management`}
                element={<TaskManagementScreen />}
              />

              <Route
                path={`/organization/:organizationId/projects/:projectId/:projectSlug/analytics`}
                element={<AnalyticsScreen />}
              />

              <Route
                path={
                  "/organization/:organizationId/projects/:projectId/:projectSlug/tasks/:taskId"
                }
                element={<Container />}
              />

              <Route
                path={
                  "/organization/:organizationId/projects/:projectId/:projectSlug/tasks/create"
                }
                element={<TaskCreationScreen />}
              />

              <Route
                path={
                  "/organization/:organizationId/projects/:projectId/:projectSlug/tasks/batch-create"
                }
                element={<BatchCreationScreen />}
              />
            </Routes>
          </Header>
        </SignedIn>
      </RecoilRoot>
    </BrowserRouter>
  );
};

const WithApolloClient = withApolloClient(ApolloClientProvider);

function App() {
  const [currentLocale, setCurrentLocale] = useState<string>("ja");
  const handleChangeLocale = (data: string) => setCurrentLocale(data);

  return (
    <ClerkProvider
      localization={getClerkLanguage(currentLocale)}
      publishableKey={clerkPubKey}
    >
      <I18nextProvider i18n={i18n}>
        <WithApolloClient handleChangeLocale={handleChangeLocale} />
      </I18nextProvider>
    </ClerkProvider>
  );
}

export default App;
