import { FC, ReactNode } from "react";
import { MembershipRole } from "@clerk/types/dist/organizationMembership";

type Props = {
  viewable: MembershipRole[];
  viewer?: MembershipRole;
  children: ReactNode;
  fallback?: ReactNode;
};
const RoleBasedVisibility: FC<Props> = ({
  children,
  fallback,
  viewer,
  viewable,
}) => (viewable.includes(viewer as any) ? <>{children}</> : <>{fallback}</>);
export default RoleBasedVisibility;
