import { FC, useState } from "react";
import { Button, Form, Input, message, Select, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { UPDATE_PROJECT_BLUEPRINT } from "../../apolloClient/mutation";
import { useParams } from "react-router-dom";
import { Project, Step } from "../../types/project";
import { GET_PROJECT_DETAIL } from "../../apolloClient/queryConstraint";
import { BUTTON_TYPE } from "../../enum/BUTTON_TYPE";

type Props = {
  currentStatus?: Step;
  currentProject?: Project;
  showCancelButton?: boolean;
  handleCancelAdding?: () => void;
};
const AddButtonActionsForm: FC<Props> = ({
  currentStatus,
  currentProject,
  showCancelButton = false,
  handleCancelAdding,
}) => {
  const { t } = useTranslation();
  const { projectId, projectSlug } = useParams();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [updateProjectBlueprint, { error }] = useMutation(
    UPDATE_PROJECT_BLUEPRINT
  );
  const options = [
    {
      label: t(BUTTON_TYPE.TEXT),
      value: BUTTON_TYPE.TEXT,
    },
    {
      label: t(BUTTON_TYPE.DEFAULT),
      value: BUTTON_TYPE.DEFAULT,
    },
  ];

  const handleSubmit = (values: Record<string, string | BUTTON_TYPE>) => {
    const currentBlueprint = currentProject?.blueprint;
    const updatedNode = {
      ...currentStatus,
      buttons: !!currentStatus?.buttons
        ? [...currentStatus.buttons, values]
        : [values],
    };

    const updatedNodes = currentProject?.blueprint.nodes.map((step) =>
      step.name === currentStatus?.name ? updatedNode : step
    );

    const updatedBlueprint = {
      ...currentBlueprint,
      nodes: updatedNodes,
    };

    setIsSubmitting(true);
    updateProjectBlueprint({
      variables: {
        slug: `${projectId}/${projectSlug}`,
        blueprint: updatedBlueprint,
      },
      refetchQueries: [GET_PROJECT_DETAIL],
    })
      .then(async () => {
        message.success({
          content: "Add action button successfully",
        });
        setIsSubmitting(false);
        if (handleCancelAdding) {
          handleCancelAdding();
        }
      })
      .catch(async () => {
        if (error)
          message.error({
            content: <>{t(error.graphQLErrors[0].extensions.code as string)}</>,
          });
        setIsSubmitting(false);
      });
  };
  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Field required",
          },
        ]}
        required
        name="name"
        label={t("button_name")}
      >
        <Select placeholder={t("input_button_name")} options={options} />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Field required",
          },
        ]}
        required
        name="label"
        label={t("button_label")}
      >
        <Input placeholder={t("input_button_label")} />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Field required",
          },
        ]}
        required
        name="action"
        label={t("action")}
      >
        <Input placeholder={t("link_to_navigate")} />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            type="primary"
            disabled={isSubmitting}
            loading={isSubmitting}
            htmlType="submit"
          >
            {t("submit")}
          </Button>
          {showCancelButton && (
            <Button onClick={handleCancelAdding}>{t("cancel")}</Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};
export default AddButtonActionsForm;
