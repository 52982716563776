import { FC } from "react";
import { Select } from "antd";
import { LOCALE } from "../../enum/LOCALE";
import { useTranslation } from "react-i18next";

type Props = {
  handleChangeLocale: (locale: string) => void;
};
const LocaleSwitcher: FC<Props> = ({ handleChangeLocale }) => {
  const { i18n } = useTranslation();
  const options = [
    { value: LOCALE.EN, label: "EN" },
    { value: LOCALE.JA, label: "JA" },
    { value: LOCALE.VI, label: "VI" },
  ];
  const handleChange = async (value: string) => {
    handleChangeLocale(value);
    await i18n.changeLanguage(value);
  };
  return (
    <Select
      options={options}
      onChange={handleChange}
      defaultValue={i18n.language}
    />
  );
};
export default LocaleSwitcher;
