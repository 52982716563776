import { FC } from "react";
import Box from "../Box/Box";
import EditableForm from "../EditableForm/EditableForm";
import { Button, Card, Typography } from "antd";
import { Step } from "../../types/project";
import { useTranslation } from "react-i18next";
import { Color } from "antd/es/color-picker";

type Props = {
  isEditing: boolean;
  currentStatus?: Step;
  handleEdit: () => void;
  handleFormSubmit: (values: Record<string, string | Color>) => void;
  handleCancelEdit: () => void;
};

const { Title, Paragraph, Text } = Typography;
const StatusSetting: FC<Props> = ({
  currentStatus,
  handleEdit,
  isEditing,
  handleCancelEdit,
  handleFormSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {isEditing && !!currentStatus ? (
        <Box>
          <Title level={3} style={{ marginTop: 0 }}>
            {t("edit_status")}
          </Title>
          <EditableForm
            step={currentStatus}
            handleSubmit={handleFormSubmit}
            handleCancel={handleCancelEdit}
          />
        </Box>
      ) : (
        <Card
          title={<Title level={3}>{t("status")}</Title>}
          extra={<Button onClick={handleEdit}>{t("edit")}</Button>}
          style={{
            minWidth: 400,
          }}
        >
          <Text>
            {t("status_name")}: {currentStatus?.label}
          </Text>
          <Paragraph>
            {t("color")}: {currentStatus?.color}
          </Paragraph>
        </Card>
      )}
    </>
  );
};

export default StatusSetting;
