import { Task } from "./task";
import { User } from "./user";

export enum HISTORY_TYPE {
  CREATE = "CREATE",
  CHANGE_STATUS = "CHANGE_STATUS",
}

export type History = {
  id: number;
  type: HISTORY_TYPE;
  createdAt: Date;
  metadata?: Record<string, unknown> | null;
  projectSlug: string;
  taskName: string;
  task?: Task;
  createdBy: string;
  user?: User;
};
