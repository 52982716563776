import { FC, useEffect, useState } from "react";
import { Button, List, message, Popconfirm, Space, Typography } from "antd";
import ButtonEditingForm from "../ButtonEditingForm/ButtonEditingForm";
import { useTranslation } from "react-i18next";
import { Project, Step } from "../../types/project";
import Box from "../Box/Box";
import AddButtonActionsForm from "../AddButtonActionsForm/AddButtonActionsForm";
import { Button as ButtonType } from "../../types/project";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useMutation } from "@apollo/client";
import { UPDATE_PROJECT_BLUEPRINT } from "../../apolloClient/mutation";
import { GET_PROJECT_DETAIL } from "../../apolloClient/queryConstraint";

type Props = {
  currentProject?: Project;
  currentStep?: Step;
  editingIndex: number | null;
  handleEditingIndex: (index: number) => void;
  handleSubmitButtonChange: (
    values: Record<string, string>,
    index: number
  ) => void;
  handleCancelEditingIndex: () => void;
  handleDeleteButton: (
    currentStatus: Step | undefined,
    button: ButtonType,
    index: number
  ) => void;
};

const { Title, Text, Paragraph } = Typography;
const ButtonActionsSetting: FC<Props> = ({
  currentProject,
  currentStep,
  handleSubmitButtonChange,
  handleCancelEditingIndex,
  handleEditingIndex,
  editingIndex,
  handleDeleteButton,
}) => {
  const { t } = useTranslation();
  const [isCreatingNewButton, setIsCreatingNewButton] =
    useState<boolean>(false);

  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [isReordering, setIsReordering] = useState(false);
  const [dataSource, setDataSource] = useState<ButtonType[] | undefined>();
  const [updateProjectBlueprint, { error }] = useMutation(
    UPDATE_PROJECT_BLUEPRINT
  );
  const onDragEnd = (result: DropResult) => {
    if (dataSource) {
      if (!result.destination) return;

      const reorderedItems = Array.from(dataSource);
      const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, reorderedItem);
      setDataSource(reorderedItems);

      const orderChanged = !dataSource.every(
        (item, index) => item === reorderedItems[index]
      );
      setIsOrderChanged(orderChanged);
    }
  };

  const handleReorder = (buttons: ButtonType[]) => {
    const updatedNode = { ...currentStep, buttons };

    const updatedNodes = currentProject?.blueprint.nodes.map((step) =>
      step.name === currentStep?.name ? updatedNode : step
    );

    const updatedBlueprint = {
      ...currentProject?.blueprint,
      nodes: updatedNodes,
    };
    setIsReordering(true);
    updateProjectBlueprint({
      variables: {
        slug: currentProject?.slug,
        blueprint: updatedBlueprint,
      },
      refetchQueries: [GET_PROJECT_DETAIL],
    })
      .then(async () => {
        message.success({
          content: "Reordered successfully",
        });
        setIsReordering(false);
      })
      .catch(async () => {
        if (error)
          message.error({
            content: <>{t(error.graphQLErrors[0].extensions.code as string)}</>,
          });
        setIsReordering(false);
      });
  };

  useEffect(() => {
    setDataSource(currentStep?.buttons);
  }, [currentStep?.buttons]);

  return (
    <div
      style={{
        margin: "20px 0",
      }}
    >
      {isCreatingNewButton ? (
        <Box>
          <Title style={{ margin: 0 }}>{t("add_action_button")}</Title>
          <Text type="secondary">{t("add_action_button_for_this_status")}</Text>
          <AddButtonActionsForm
            currentStatus={currentStep}
            currentProject={currentProject}
            handleCancelAdding={() => setIsCreatingNewButton(false)}
            showCancelButton
          />
        </Box>
      ) : !!dataSource?.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"button_action_list"}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <List
                  header={
                    <Space size={20} align="baseline">
                      <Title level={3}>{t("button_actions")}</Title>
                      {isCreatingNewButton ? null : (
                        <Button onClick={() => setIsCreatingNewButton(true)}>
                          {t("create_new_action_button")}
                        </Button>
                      )}
                      <Button
                        type="primary"
                        disabled={isReordering || !isOrderChanged}
                        loading={isReordering}
                        onClick={() => handleReorder(dataSource)}
                      >
                        {t("save")}
                      </Button>
                    </Space>
                  }
                  dataSource={dataSource}
                  renderItem={(button, index) => {
                    const { label, action, name } = button;
                    return (
                      <Draggable
                        key={index.toString()}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <List.Item
                            ref={provided.innerRef}
                            actions={[
                              <>
                                {editingIndex === index ? null : (
                                  <Button
                                    onClick={() => handleEditingIndex(index)}
                                  >
                                    {t("edit")}
                                  </Button>
                                )}
                              </>,

                              <>
                                {editingIndex === index ? null : (
                                  <Popconfirm
                                    title="Delete button"
                                    description="Are you sure to delete this button?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() =>
                                      handleDeleteButton(
                                        currentStep,
                                        button,
                                        index
                                      )
                                    }
                                  >
                                    <Button danger>{t("delete")}</Button>
                                  </Popconfirm>
                                )}
                              </>,
                            ]}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              background: "white",
                              ...provided.draggableProps.style,
                            }}
                          >
                            {editingIndex === index ? (
                              <Box>
                                <ButtonEditingForm
                                  currentIndex={index}
                                  button={button}
                                  handleSubmit={handleSubmitButtonChange}
                                  handleCancel={handleCancelEditingIndex}
                                />
                              </Box>
                            ) : (
                              <List.Item.Meta
                                title={
                                  <Text>
                                    {t("button_label")}: {label}
                                  </Text>
                                }
                                description={
                                  <>
                                    <Paragraph
                                      style={{
                                        marginBottom: 0,
                                      }}
                                    >
                                      {t("move_to")}: {action}
                                    </Paragraph>
                                    <Paragraph
                                      style={{
                                        marginBottom: 0,
                                      }}
                                    >
                                      {t("name")}: {name}
                                    </Paragraph>
                                  </>
                                }
                              />
                            )}
                          </List.Item>
                        )}
                      </Draggable>
                    );
                  }}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Box>
          <Title style={{ margin: 0 }}>{t("add_action_button")}</Title>
          <Text type="secondary">{t("add_action_button_for_this_status")}</Text>
          <AddButtonActionsForm
            currentStatus={currentStep}
            currentProject={currentProject}
          />
        </Box>
      )}
    </div>
  );
};
export default ButtonActionsSetting;
