import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useState } from "react";

export default function useUpload() {
  const [downloadUrl, setDownloadUrl] = useState<string>();

  const handleUpload = async (file: File) => {
    const storage = getStorage();
    const storageRef = ref(storage, "csv/" + file.name);

    await uploadBytes(storageRef, file);
    const downloadUrl = await getDownloadURL(storageRef);

    setDownloadUrl(downloadUrl);
  };

  return {
    downloadUrl,
    handleUpload,
  };
}
