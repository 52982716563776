import { FC, useState } from "react";
import OrganizationAuthorization from "../OrganizationAuthorization/OrganizationAuthorization";
import { useOrganization } from "@clerk/clerk-react";
import { Button, message, Popconfirm, Typography } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Color } from "antd/es/color-picker";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PROJECT_DETAIL } from "../../apolloClient/queryConstraint";
import { Blueprint, Connection, Project, Step } from "../../types/project";
import { getCurrentStatus, updatedButtonsByStatus } from "../../utils/utilize";
import { UPDATE_PROJECT_BLUEPRINT } from "../../apolloClient/mutation";
import { useTranslation } from "react-i18next";
import ButtonsSetting from "../ButtonsSetting/ButtonsSetting";
import ButtonActionsSetting from "../ButtonActionsSetting/ButtonActionsSetting";
import StatusSetting from "../StatusSetting/StatusSetting";
import { Button as ButtonType } from "../../types/project";
import { ArrowLeftOutlined } from "@ant-design/icons";

type Props = {};

const { Title, Text } = Typography;

const StatusSettingScreen: FC<Props> = () => {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const { projectId, projectSlug, statusName } = useParams();
  const [messageApi] = message.useMessage();
  const nav = useNavigate();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const [currentGraphIndex, setCurrentGraphIndex] = useState<number | null>(
    null
  );
  const { data: queryData } = useQuery(GET_PROJECT_DETAIL, {
    variables: {
      slug: `${projectId}/${projectSlug}`,
    },
  });
  const currentProject: Project | undefined = queryData?.projectDetail;
  const currentStatus = getCurrentStatus(statusName, currentProject);

  const [updateProjectBlueprint, { error }] = useMutation(
    UPDATE_PROJECT_BLUEPRINT
  );

  const handleEdit = () => setIsEditing(true);

  const handleEditingIndex = (index: number) => setEditingIndex(index);

  const handleChangeGraphIndex = (index: number) => setCurrentGraphIndex(index);

  const handleCancelChangeGraphIndex = () => setCurrentGraphIndex(null);

  const handleCancelEditingIndex = () => setEditingIndex(null);

  const handleDeleteButton = (currentGraph: Connection) => {
    if (currentProject) {
      const currentConnections = currentProject.blueprint.graph;
      const updatedConnections = currentConnections.filter(
        (item) => JSON.stringify(item) !== JSON.stringify(currentGraph)
      );

      const updatedBlueprint = {
        ...currentProject.blueprint,
        graph: updatedConnections,
      };

      updateProjectBlueprint({
        variables: {
          slug: `${projectId}/${projectSlug}`,
          blueprint: updatedBlueprint,
        },
        refetchQueries: [GET_PROJECT_DETAIL],
      })
        .then(async () => {
          await messageApi.success({
            content: "Delete successfully",
          });
          setEditingIndex(null);
        })
        .catch(async () => {
          if (error)
            await messageApi.error({
              content: (
                <>{t(error.graphQLErrors[0].extensions.code as string)}</>
              ),
            });
        });
    }
  };

  const handleDeleteStatus = () => {
    if (currentProject) {
      const updatedConnections = currentProject.blueprint.graph
        .map((edge) => {
          if (edge.to === currentStatus?.name) {
            return { ...edge, to: "completed" };
          }
          return edge;
        })
        .filter(({ from }) => from !== currentStatus?.name);
      const updatedNodes = [
        ...currentProject.blueprint.nodes.filter(
          ({ name }) => name !== statusName
        ),
      ];

      const updatedBlueprint: Blueprint = {
        ...currentProject.blueprint,
        graph: updatedConnections,
        nodes: updatedNodes,
      };

      updateProjectBlueprint({
        variables: {
          slug: `${projectId}/${projectSlug}`,
          blueprint: updatedBlueprint,
        },
        refetchQueries: [GET_PROJECT_DETAIL],
      })
        .then(() => {
          nav(
            `/organization/${organization?.id}/projects/${projectId}/${projectSlug}`
          );
        })
        .catch(async () => {
          if (error)
            await messageApi.error({
              content: (
                <>{t(error.graphQLErrors[0].extensions.code as string)}</>
              ),
            });
        });
    }
  };

  const handleDeleteButtonAction = (
    currentStep: Step | undefined,
    button: ButtonType
  ) => {
    const currentBlueprint = currentProject?.blueprint;
    const updatedButtonAction = currentStep?.buttons?.filter(
      (item) => JSON.stringify(item) !== JSON.stringify(button)
    );

    const updatedNode = {
      ...currentStep,
      buttons: updatedButtonAction,
    };

    const updatedNodes = currentBlueprint?.nodes.map((step) =>
      step.name === currentStep?.name ? updatedNode : step
    );

    const updatedBlueprint = {
      ...currentBlueprint,
      nodes: updatedNodes,
    };

    updateProjectBlueprint({
      variables: {
        slug: `${projectId}/${projectSlug}`,
        blueprint: updatedBlueprint,
      },
      refetchQueries: [GET_PROJECT_DETAIL],
    })
      .then(async () => {
        await messageApi.success({
          content: "Delete successfully",
        });
        setEditingIndex(null);
      })
      .catch(async () => {
        if (error)
          await messageApi.error({
            content: <>{t(error.graphQLErrors[0].extensions.code as string)}</>,
          });
      });
  };
  const handleFormSubmit = (values: Record<string, string | Color>) => {
    if (currentProject) {
      const currentBlueprint = { ...currentProject.blueprint };
      const updatedData = {
        ...currentStatus,
        ...values,
        color:
          typeof values.color === "string"
            ? values.color
            : values.color.toHexString(),
      };

      const updatedNodes = currentBlueprint.nodes.map((node) =>
        node.name === currentStatus?.name ? updatedData : node
      );

      const updatedGraph = currentBlueprint.graph.map((link) =>
        link.from === currentStatus?.name
          ? {
              ...link,
              from: updatedData.name,
              color: updatedData.color,
            }
          : {
              ...link,
              to: updatedData.name,
            }
      );

      const updatedBlueprint = {
        graph: updatedGraph,
        nodes: updatedNodes,
      };

      updateProjectBlueprint({
        variables: {
          slug: `${projectId}/${projectSlug}`,
          blueprint: updatedBlueprint,
        },
        refetchQueries: [GET_PROJECT_DETAIL],
      })
        .then(async () => {
          nav(
            `/organization/${organization?.id}/projects/${projectId}/${projectSlug}/statuses/${updatedData.name}`
          );
          setIsEditing(false);
        })
        .catch(async () => {
          if (error)
            await messageApi.error({
              content: (
                <>{t(error.graphQLErrors[0].extensions.code as string)}</>
              ),
            });
          setIsEditing(false);
        });
    }
  };

  const handleSubmitButtonChange = (
    values: Record<string, string>,
    index: number
  ) => {
    if (currentProject && currentStatus) {
      const currentBlueprint = currentProject.blueprint;
      const updatedNode = updatedButtonsByStatus(currentStatus, values, index);

      const updatedNodes = currentProject.blueprint.nodes.map((step) =>
        step.name === statusName ? updatedNode : step
      );

      const updatedBlueprint = { ...currentBlueprint, nodes: updatedNodes };
      updateProjectBlueprint({
        variables: {
          slug: `${projectId}/${projectSlug}`,
          blueprint: updatedBlueprint,
        },
        refetchQueries: [GET_PROJECT_DETAIL],
      })
        .then(async () => {
          await messageApi.success({
            content: "Update successfully",
          });
          setEditingIndex(null);
        })
        .catch(async () => {
          if (error)
            await messageApi.error({
              content: (
                <>{t(error.graphQLErrors[0].extensions.code as string)}</>
              ),
            });
        });
    }
  };

  const handleSubmitGraphChange = (values: Record<string, string>) => {
    if (currentProject) {
      const currentGraphs = currentProject.blueprint.graph;
      const updatedGraphs = currentGraphs.map((link) =>
        link.from === statusName
          ? {
              ...link,
              ...values,
            }
          : link
      );

      const updatedBlueprint = {
        ...currentProject.blueprint,
        graph: updatedGraphs,
      };

      updateProjectBlueprint({
        variables: {
          slug: `${projectId}/${projectSlug}`,
          blueprint: updatedBlueprint,
        },
        refetchQueries: [GET_PROJECT_DETAIL],
      })
        .then(async () => {
          await messageApi.success({
            content: "Update successfully",
          });
          setCurrentGraphIndex(null);
        })
        .catch(async () => {
          if (error)
            await messageApi.error({
              content: (
                <>{t(error.graphQLErrors[0].extensions.code as string)}</>
              ),
            });
        });
    }
  };
  const handleCancelEdit = () => setIsEditing(false);

  return (
    <OrganizationAuthorization organization={organization}>
      <Link
        to={`/organization/${organization?.id}/projects/${projectId}/${projectSlug}`}
      >
        <Button type="link" icon={<ArrowLeftOutlined />}>
          {t("back_to_project_screen")}
        </Button>
      </Link>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={2}>{t("status_setting")}</Title>
        <Popconfirm
          title="Delete status"
          description="Are you sure to delete this status?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleDeleteStatus()}
        >
          <Button danger>{t("delete")}</Button>
        </Popconfirm>
      </div>
      <Text>
        {t("project")}: {currentProject?.name}
      </Text>

      <StatusSetting
        currentStatus={currentStatus}
        isEditing={isEditing}
        handleEdit={handleEdit}
        handleFormSubmit={handleFormSubmit}
        handleCancelEdit={handleCancelEdit}
      />

      <ButtonsSetting
        handleDeleteButton={handleDeleteButton}
        currentStatus={currentStatus}
        currentProject={currentProject}
        currentGraphIndex={currentGraphIndex}
        handleChangeGraphIndex={handleChangeGraphIndex}
        handleCancelChangeGraphIndex={handleCancelChangeGraphIndex}
        handleSubmitGraphChange={handleSubmitGraphChange}
      />

      <ButtonActionsSetting
        editingIndex={editingIndex}
        handleEditingIndex={handleEditingIndex}
        handleSubmitButtonChange={handleSubmitButtonChange}
        handleCancelEditingIndex={handleCancelEditingIndex}
        currentStep={currentStatus}
        currentProject={currentProject}
        handleDeleteButton={handleDeleteButtonAction}
      />
    </OrganizationAuthorization>
  );
};
export default StatusSettingScreen;
